import React, {Component} from "react";
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";
import {withRouter} from 'react-router-dom'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

class Schedule extends Component {

  constructor(props) {
    super(props);
    this.state = {
      events: [],
      custom_events: [],
      views: {
        month: true,
        week: true,
        day: true,
      },
      eventInfo: null,
    };
  }

  async componentDidMount() {
    let set = this.props.match.params.name
    await axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: "VTS/teacher/en/calender_sessions/" + set
        url: `api/method/variiance.vts_app.api.v2.teacher.calender_sessions?obj_id=${set}`
      }
    })
      .then((response) => {
        let custom_events = response.data.data
        this.setState({custom_events});
      });
  }

  render() {
    const {custom_events} = this.state;
    const eventList = custom_events.map((event) => {
      return {
        id: event.name,
        title: event.title,
        start: new Date(event.date + "T" + event.start_time),
        end: new Date(event.date + "T" + event.end_time),
        allDay: false,
      };
    });
    return (
      <DnDCalendar
        defaultDate={moment().toDate()}
        //   defaultView="month"
        ViewKey={['month', 'day', 'agewwwnda']}
        events={eventList}
        localizer={localizer}
        //   onEventDrop={this.onEventDrop}
        //   onEventResize={this.onEventResize}
        //   resizable
        style={{height: "70vh"}}
      />
    );
  }
}

export default withRouter(Schedule);
