import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import img from "../images/session.png";
import axios from "axios";
import PDF from "../images/pdf.png";
import word from "../images/word.png";
import Excel from "../images/Excel.png";
import Power from "../images/powerpoint.png";
import {AiOutlineCloudDownload} from "react-icons/ai";

class Files extends Component {
  state = {
    isLoading: true,
    files: [],
    session_id: this.props.session_id,
    teacher: this.props.teacher
  };

  async componentDidMount() {
    await axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: `api/resource/Session File?filters=[["session","=","${this.state.session_id}"]]&fields=["*"]&order_by=creation desc`
        url: `api/resource/Session File?filters=[["session","=","${this.state.session_id}"]]&fields=["*"]&order_by=creation desc`,
      }
    }).then((response) => {
      let files = response.data.data;
      this.setState({files});
      this.setState({
        isLoading: false,
      });
    });
  }

  render() {
    const {t} = this.props;
    const {files} = this.state;
    const Files_arr = files.map((res) => {
      return (
        <div className=" mt-2 filecard1" key={Math.random()}>
          <div className="file11">
            <div className="instimg">
              {res.type === "PDF" ? (
                <img src={PDF} alt="pdf" width={40} height={40}/>
              ) : res.type === "Word" ? (
                <img src={word} alt="word" width={40} height={40}/>
              ) : res.type === "Excel" ? (
                <img src={Excel} alt="Excel" width={40} height={40}/>
              ) : res.type === "Power Point" ? (
                <img src={Power} alt="Power" width={40} height={40}/>
              ) : null}
            </div>
            <div>
              <h5 className="filename">
                {res.title}
              </h5>
            </div>
          </div>
          <div className="file22">
            <div>
              <a href={res.link} target="_blank" rel="noreferrer">
                <AiOutlineCloudDownload className="Lable2"/>
              </a>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="my-5">
        <hr/>
        <h4 className="head111 mb-5">{t("Files")}</h4>
        {Files_arr}
      </div>
    );
  }
}

export default withTranslation()(Files);
