import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import img from "../images/session.png";
import axios from "axios";
import img2 from "../images/ava.png";
import Moment from "react-moment";
import keycloak from "../../keycloak";
import {AiFillStar, TiDeleteOutline} from "react-icons/all";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

class Announcements extends Component {
    state = {
        isLoading: true,
        comments: [],
        comment: "",
        session_id: this.props.session_id,
        teacher: this.props.teacher,
        lang: this.props.lang,
        disabled: "disabled",
        posting: false,
    };


    async getComments() {
        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                // url: "VTS/session/en/comments/" + this.state.session_id
                url: `api/method/variiance.vts_app.api.v2.session.comments?obj_id=${this.state.session_id}`
            }
        })
            .then((response) => {
                let comments = response.data.data;
                this.setState({comments});
                this.setState({
                    isLoading: false,
                });
            });
    }

    async componentDidMount() {
        await this.getComments()
    }

    handleChange = (event) => {
        if (event.target.value !== "") {
            this.setState({disabled: false});
        } else {
            this.setState({disabled: "disabled"});
        }
        this.setState({[event.target.name]: event.target.value});
    };

    _handleKeyDown = (e) => {
        if (e.key === "Enter") {
            this.handleSubmit();
        }
    };

    handleSubmit = () => {
        this.setState({disabled: "disabled"});
        this.setState({posting: true});
        const newParams = {
            // url: "VTS/session/en/create_comment",
            url: "api/method/variiance.vts_app.api.v2.session.create_comment",
            data: {
                email: keycloak.tokenParsed.email,
                session: this.state.session_id,
                author_type:
                    this.state.teacher.email === keycloak.tokenParsed.email
                        ? "Instructor"
                        : "Student",
                content: this.state.comment,
            },
        };

        axios.post(process.env.REACT_APP_API_URL, newParams)
            .then((response) => {
                if (response.status === 200) {
                    axios.get(process.env.REACT_APP_API_URL, {
                        params: {
                            // url: "VTS/session/en/comments/" + this.state.session_id
                            url: `api/method/variiance.vts_app.api.v2.session.comments?obj_id=${this.state.session_id}`
                        }
                    })
                        .then((response) => {
                            let comments = response.data.data;
                            this.setState({comments});
                            this.setState({
                                isLoading: false,
                                posting: false,
                                comment: "",
                            });
                        });
                }
            });
    };

    deleteComment = (id) => {
        this.setState({isLoading: true})
        axios.delete(process.env.REACT_APP_API_URL, {
            params: {
                url: "api/resource/Session Comment/" + id
            }
        })
            .then(response => {
                this.getComments()
            })
    }

    render() {
        const {t} = this.props;
        const {comments, teacher} = this.state;
        const comment = comments.map((res) => {
            return (
                <div key={res.name}>
                    <div className=" filecard my-2">
                        <div className="file1">
                            <div className="instimg">
                                <img
                                    className="commimg"
                                    src={
                                        res.image === null || res.image === "" ? img2 : res.image
                                    }
                                    width={40}
                                    height={40}
                                    alt={res.image_alt}
                                />
                            </div>
                            <div>
                                <h5 className="filename">
                                    {res.author_name}{" "}
                                    {res.author_type === "Instructor" ? (
                                        <AiFillStar
                                            title="instructor"
                                            style={{color: "#f2871e"}}
                                        />
                                    ) : null}{" "}
                                </h5>
                                <p
                                    className="filetxt"
                                    dangerouslySetInnerHTML={{__html: res.content}}
                                ></p>
                            </div>
                        </div>
                        <div className="file2">
                            {(keycloak.authenticated && teacher.email === keycloak.tokenParsed.email) ?
                                <TiDeleteOutline style={{cursor: "pointer"}} size={25} color="#f83d3d"
                                                 onClick={() => {
                                                     confirmAlert({

                                                         title: <h1 className="alertH1">{t("Are you sure ?")}</h1>,
                                                         message: <h1 className="text-center alertSize">
                                                             {t("you sure about to delete this comment ?")}</h1>,
                                                         buttons: [

                                                             {
                                                                 label: t("No"),
                                                                 className: "no"


                                                             }, {
                                                                 label: t("Delete"),
                                                                 className: "delete",
                                                                 onClick: () => {
                                                                     this.deleteComment(res.name)
                                                                 }
                                                             }
                                                         ]
                                                     });
                                                 }}
                                /> : null}

                            {localStorage.getItem("i18nextLng") === "en" ||
                            localStorage.getItem("i18nextLng") === "en-US" ? (
                                <p className="filetxt">
                                    <Moment fromNow ago>
                                        {res.creation}
                                    </Moment>
                                    &nbsp;ago
                                </p>
                            ) : (
                                <p className="filetxt">
                                    منذ&nbsp;
                                    <Moment fromNow ago>
                                        {res.creation}
                                    </Moment>
                                </p>
                            )}
                        </div>
                    </div>
                    <hr style={{marginInline: "20px"}}/>
                </div>
            );
        });
        return (
            <div className="my-5">
                <hr/>
                <h4 className="head111 mb-5">{t("Comments")}</h4>
                <div className="input-group my-5 px-5">
                    <div className="input-group-prepend">
                        <button
                            disabled={this.state.disabled}
                            className="btn btn-outline-secondary"
                            id="button-addon1"
                            onClick={() => this.handleSubmit()}
                            value="Submit"
                            type="submit"
                        >
                            {this.state.posting ? (
                                <span>{t("posting")}</span>
                            ) : (
                                <span className="plus">+</span>
                            )}
                        </button>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        name="comment"
                        value={this.state.comment}
                        placeholder={t("Start typing")}
                        aria-label="Example text with button addon"
                        aria-describedby="button-addon1"
                        onChange={this.handleChange}
                        onKeyDown={this._handleKeyDown}
                    />
                </div>
                {comment}
            </div>
        );
    }
}

export default withTranslation()(Announcements);
