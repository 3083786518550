import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import img from "../images/chat (1).svg"
import {Link} from "react-router-dom";


class PopupForm extends Component {




    render() {
        const {t} = this.props;

        return (
            <Link to="/JoinAsTeacher">
            <div className="modalsecForm">
                <div className="my-2">
                    <img className="m-auto d-block" src={img} alt="join as teacher" width="40px" height="40px"/>
                    <p className="modaltxtForm">{t("Join As Teacher")}</p>
                </div>
            </div>
            </Link>
        );
    }
}

export default withTranslation()(PopupForm);
