import React, { Component } from "react";
// import './all.css';
import logofoot from "./images/Teaching-Light-8.png";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import {
  AiFillYoutube,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import {SiMaildotru, SiTiktok} from "react-icons/all";
import { withTranslation } from "react-i18next";
import keycloak from "./../keycloak";
import { Link } from "react-router-dom";

class Footer extends Component {
  state = {};

  render() {
    const { t } = this.props;

    return (
      <>
        <div className="footerbg">
          <div className="container">
            <div className="row pt-5">
              <div className="col-lg-4 col-md-4">
                <a
                  style={{ textDecoration: "none" }}
                  href="https://variiance.com/"
                >
                  {" "}
                  <img
                    className="my-5 footimg"
                    alt="footer logo"
                    src={logofoot}
                    width={120}
                    height={70}
                  />
                </a>
                <div className="icotext">
                  <span className="spicon">
                    <FaMapMarkerAlt
                      style={{
                        fontSize: "27px",
                        color: "#f2871e",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                  <h5 className=" pb-5 h1footer">{t("infoFoot")}</h5>
                </div>
                <div className="divfooter">
                  <div className="icotext">
                    <span className="spicon">
                      <FaPhoneAlt
                        style={{
                          fontSize: "27px",
                          color: "#f2871e",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                    <h5 className=" pb-5 h1footer"> 01021132773 </h5>
                  </div>
                  <div className="icotext">
                    <span className="spicon">
                      <SiMaildotru
                        style={{
                          fontSize: "27px",
                          color: "#f2871e",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                    <h5 className=" pb-5 h1footer">VTS@variiance.com</h5>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-md-8">
                <div className="row">
                  <div className="col-lg-4 text-center col-md-4">
                    <h2 className="my-5 hfooter">{t("Company")}</h2>
                    <a
                      style={{ textDecoration: "none" }}
                      href="https://variiance.com/"
                    >
                      {" "}
                      <p className=" pb-2 h1footer txtfooter">
                        {t("Home")}
                      </p>{" "}
                    </a>
                    <a
                      style={{ textDecoration: "none" }}
                      href="https://variiance.com"
                    >
                      {" "}
                      <p className=" pb-2 h1footer txtfooter">
                        {t("Products")}
                      </p>{" "}
                    </a>
                    {/* <p className="pb-2 h1footer">{t('FAQ')}</p>
                                        <p className=" pb-2 h1footer">{t('Blog')}</p> */}
                  </div>

                  <div className="col-lg-4 text-center col-md-4">
                    <h2 className="my-5 hfooter">{t("Quick Links")}</h2>
                    <span
                      style={{ textDecoration: "none" ,cursor:"pointer"}}
                      onClick={() => keycloak.register()}
                    >
                      {" "}
                      <p className=" pb-2 h1footer txtfooter">
                        {t("Register Now")}
                      </p>
                    </span>
                    {/* <p className=" pb-2 h1footer">{t('All Courses')}</p> */}
                    <Link style={{ textDecoration: "none" }} to="/">
                      {" "}
                      <p className="pb-2 h1footer txtfooter">
                        {t("All Categories")}
                      </p>{" "}
                    </Link>
                    {/* <p className=" pb-2 h1footer">{t('Blog')}</p> */}
                  </div>

                  <div className="col-lg-4  text-center col-md-4">
                    <h2 className="my-5 hfooter">{t("Information")}</h2>
                    <a href="https://cloud.variiance.com/index.php/s/FENfqR4PrkHbTJt">
                    <p className=" pb-2 h1footer txtfooter">
                      {t("Privacy Policy")}
                    </p>
                    </a>
                    <a href="https://cloud.variiance.com/index.php/s/sbnXJnzzYA3oCc9">
                    <p className=" pb-2 h1footer txtfooter">
                      {t("Terms & Condition")}
                    </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ color: "white" }} />
            <div className="row py-5">
              <div className="col-lg-7 col-md-5">
                <h2 className=" h1footer mobfoottxt">
                  {t("Copyright © ")}  {new Date().getFullYear()}  {t(" variiance. All Rights Reserved")}
                </h2>
              </div>

              <div className="col-lg-5 col-md-5">
                <div className="icotext1">
                  <a href="https://www.facebook.com/Variiance/">
                    <span className="spicon">
                      <FaFacebookF
                        style={{
                          fontSize: "27px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </a>
                  <a href="https://twitter.com/Variianceglobal">
                    {" "}
                    <span className="spicon">
                      <AiOutlineTwitter
                        style={{
                          fontSize: "27px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </a>
                  <a href="https://www.instagram.com/variiance/">
                    {" "}
                    <span className="spicon">
                      <AiOutlineInstagram
                        style={{
                          fontSize: "27px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </a>
                  <a href="https://www.youtube.com/channel/UCgwG6u7lMPW3I6NVc2cCEVA?view_as=subscriber">
                    {" "}
                    <span className="spicon">
                      <AiFillYoutube
                        style={{
                          fontSize: "27px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </a>
                  <a href="https://www.linkedin.com/company/variianceglobal/">
                    {" "}
                    <span className="spicon">
                      <FaLinkedinIn
                        style={{
                          fontSize: "27px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </a>
                  <a href="https://www.tiktok.com/@variiance.eco">
                    {" "}
                    <span className="spicon">
                      <SiTiktok
                          style={{
                            fontSize: "27px",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                      />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(Footer);
