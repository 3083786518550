import React, {Component} from "react";
import {Translation, withTranslation} from "react-i18next";
import keycloak from "../../keycloak";
import i18n from "../i18n/i18n";
import axios from "axios";
import {toast} from "react-toastify";
import {withRouter} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

class BookSessionButton extends Component {
  state = {
    posting: false,
    status: this.props.status,
    book_session_class_name: 'buttonbg2',
    sessionData: this.props.sessionData,
    book_session_button: t("Book This Session"),
    disabled_button: false,
    alert_message: '',
    alert_status: "",
    teacher: this.props.teacher,
  }

  login = () => {
    this.setState({posting: true, book_session_class_name: 'button-disabled'})
    keycloak.login()
  }

  notify_success = (message) => toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  notify_error = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  goToCartPage = () => {
    this.setState({posting: true})
    if (keycloak && keycloak.authenticated) {
      let session = {
        buyable_type: 'Session',
        buyable_id: this.state.sessionData.name,
        price: this.state.sessionData.price,
        discount: 0,
        expiry_date: `${this.state.sessionData.date+" "+this.state.sessionData.start_time}`
      };
      const newparams = {
        // url: "payment_portal/ar/add_to_cart",
        url: "/api/method/variiance.payment_app.api.v2.payment_portal.add_to_cart",
        data: {
          user_email: keycloak.tokenParsed.email,
          first_name: keycloak.tokenParsed.given_name,
          last_name: keycloak.tokenParsed.family_name,
          student_mobile_number: keycloak.tokenParsed.phone_number ? keycloak.tokenParsed.phone_number : '',
          cart_items: [session]
        },
      };

      axios.post(process.env.REACT_APP_API_URL, newparams).then((response) => {
        let res = response.status;
        if (res === 200) {
          setTimeout(() => {
            window.location.href = process.env.REACT_APP_PAYMENT + '/cartPage';
          }, 500);
        } else {
          alert('something went wrong')
          this.setState({posting: false})
        }
      });
    }
  }

  book_session = () => {
    this.setState({disabled_button: "disabled", book_session_class_name: 'button-disabled'});
    if (!keycloak.authenticated) {
      this.notify_error('Please login first to can book this session, we will redirect after 3 secs')
      return 0
    }
    const newParams = {
      // url: "VTS/student/en/book_session/" + this.props.match.params.name,
      url: "api/method/variiance.vts_app.api.v2.student.book_session",
      data: {
        obj_id: this.props.match.params.name,
        student: keycloak.tokenParsed.email,
        name: keycloak.tokenParsed.given_name
      },
    };

    axios.post(process.env.REACT_APP_API_URL, newParams).then((response) => {
      let session_booked = response.data;
      this.setState({alert_message: session_booked.message});
      if (session_booked.status) {
        this.notify_success(this.state.alert_message)
        if (session_booked.request_status === 'Approved') {
          setTimeout(() => {
            window.location.href = process.env.REACT_APP_PAYMENT + "/cartpage";
          }, 5000);
        }
        if (session_booked.request_status === 'Owned') {
          this.setState({'status': 'Owned'})
        }
      } else {
        this.notify_error(this.state.alert_message)
      }

     if (session_booked.request_status === 'Rejected') {
       this.setState({
         book_session_button: <Translation i18n={i18n}>
           {
             (t, {i18n}) => <span className="Lable">{t("Request Rejected")}</span> // will be looked up from namespace ns1
           }
         </Translation>
       })
      }

      if (session_booked.request_status === 'Pending') {
        this.setState({
          book_session_button: <Translation i18n={i18n}>
            {
              (t, {i18n}) => <span className="Lable">{t("Request Pending")}</span> // will be looked up from namespace ns1
            }
          </Translation>
        })
      }

    });
  };

  render() {

    if (keycloak && !keycloak.authenticated) {
      return <div className=" mx-3">
        <button
          onClick={() => this.login()}
          className={this.state.book_session_class_name}
        >
          {this.state.posting ?
            <span>{t('Redirecting...')}</span>
            :
            <span className="Lable">{t('Login To Book')}</span>
          }
        </button>
      </div>
    } else if (this.state.status !== 'Owned' && this.state.sessionData.session_limit === this.state.sessionData.student_count) {
      return <div className=" mx-3">
        <button className="button-disabled" disabled>
          <span className="Lable">{t("Session Limit Reached")}</span>
        </button>
      </div>
    } else if (this.state.status === 'Owned') {
      return <div className=" mx-3">
        <button
          disabled
          onClick=''
          className="button-disabled"
        >
          <span className="Lable">{t("Already Purchased")}</span>
        </button>
      </div>
    } else if (this.state.status === 'Approved') {
      return <div className=" mx-3">
        <button
          onClick={() => this.goToCartPage()}
          className={this.state.posting ? 'button-disabled' : 'buttonbg2'}
        >
          {this.state.posting ? <span>{t("Redirecting...")}</span> : <span className="Lable">{t("Pay Now")}</span>}
        </button>
      </div>
    } else if (this.state.status === 'Pending') {
      return <div className=" mx-3">
        <button className="button-disabled" disabled>
          <span className="Lable">{t("Request Pending")}</span>
        </button>
      </div>
    } else if (this.state.status === 'Rejected') {
      return <div className=" mx-3">
        <button className="button-disabled" disabled>
          <span className="Lable">{t("Request Rejected")}</span>
        </button>
      </div>
    } else {
      return <div className=" mx-3">
        <button
          disabled={this.state.disabled_button}
          onClick={() => this.book_session()}
          className={this.state.book_session_class_name}
        >
          {this.state.book_session_button}
        </button>
      </div>
    }
  }

}

function t(key) {
  return <Translation i18n={i18n}>
    {
      (t, {i18n}) => <span className="Lable">{t(key)}</span>// will be looked up from namespace ns1
    }
  </Translation>
}

export default withRouter(withTranslation()(BookSessionButton));
