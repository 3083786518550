import React, {Component} from "react";
import star from "./images/star.png";
import "./pages.css";
import searchico from "../pages/images/searchico.png";
import {withTranslation} from "react-i18next";
import {Badge, Button, Checkbox, Spin} from "antd";
import {Link, withRouter} from "react-router-dom";
import img from "../pages/images/session.png";
import Empty from "../pages/components/empty";
import axios from "axios";
import "moment/locale/ar";
import "moment/locale/en-au";
import MetaTags from "react-meta-tags";
import {Helmet} from "react-helmet";

const moment = require("moment");


let selected_option = [];
let selected_option1 = [];
let stringData = "";
let stringData1 = "";
let queryYear = "";
let querySubject = "";
let query = "";

class Sessions extends Component {
  state = {
    sessions: [],
    isLoading: true,
    subjects: [],
    years: [],
    searchText: "",
    selectedOption: [],
    selected_option1: [],
    checkedList: [],
    checkedList1: [],
    subjectQuery: [],
    yearQuery: [],
    questinId: "",
    sessions1: [],
    pag: 0,
    total: null,
    per_page: 4,
    current_page: 1,
    metaItemsName: [],
    metaItemsProperty: [],
    url: "",
    metatittle: ''
  };
  handleSearch = (e) => {
    let page = e || this.props.match.params.page || 1;
    let limit = 4;

    // limit = page * 4 - 4
    let href = "/sessions/" + page;
    this.props.history.push({
      pathname: href,
    });
    if (selected_option.length === 0 && selected_option1.length === 0) {
      query = "";
    } else if (selected_option1.length === 0) {
      query = querySubject;
    } else if (selected_option.length === 0) {
      query = queryYear;
    } else {
      query = querySubject + "&" + queryYear;
    }
    let lang = "";
    if (
      localStorage.getItem("i18nextLng") === "en" ||
      localStorage.getItem("i18nextLng") === "en-US"
    ) {
      this.setState({lang: "en"});
      lang = "en";
    } else {
      this.setState({lang: "ar"});
      lang = "ar";
    }

    axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: `VTS/session/${lang}/index?status=accepted&limit=${limit}&page=${page}&${query}&q=${this.state.searchText}`
        url: `api/method/variiance.vts_app.api.v2.session.index?lang=${lang}&status=accepted&limit=${limit}&page=${page}&${query}&q=${this.state.searchText}`

      }
    })
      .then((response) => {
        let sessions = response.data.data;
        this.setState({
          sessions,
          results: response.data.data.records,
          total: response.data.meta.total_count,
          current_page: response.data.meta.current_page,
          pag: response.data.meta.pagination,
          isLoading: false,
        });
        this.topscroll();
      });
  };

  componentDidMount() {
    this.setState({url: this.props.match.url})

    selected_option1 = [];
    selected_option = [];
    let page = this.props.match.params.page || 1;
    let href = "/sessions/" + page;
    this.props.history.push({
      pathname: href,
    });

    let lang = "";
    if (
      localStorage.getItem("i18nextLng") === "en" ||
      localStorage.getItem("i18nextLng") === "en-US"
    ) {
      this.setState({lang: "en"});
      lang = "en";
      moment.locale('en-au');
    } else {
      this.setState({lang: "ar"});
      lang = "ar";
      moment.locale('ar');
    }

    axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: `VTS/session/${lang}/index?status=accepted&limit=4&page=${page}`
        url: `api/method/variiance.vts_app.api.v2.session.index?status=accepted&limit=4&page=${page}&lang=${lang}`
      }
    })
      .then((response) => {
        let sessions = response.data.data;
        this.setState({
          sessions,
          results: response.data.data.records,
          total: response.data.meta.total_count,
          current_page: response.data.meta.current_page,
          pag: response.data.meta.pagination,
          isLoading: false,
        });
        this.topscroll();
      });

    axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: `VTS/session/${lang}/subjects`
        url: `api/method/variiance.vts_app.api.v2.session.subjects?lang=${lang}`
      }
    })
      .then((response) => {
        let subjects = response.data.data;

        this.setState({subjects});
      });

    axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: `VTS/session/${lang}/years`
        url: `api/method/variiance.vts_app.api.v2.session.years?lang=${lang}`
      }
    })
      .then((response) => {
        let years = response.data.data;

        this.setState({years});
      });

    axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: "api/resource/VTSMetaTags/Sessions_VTS_" + lang
        url: "api/resource/VTSMetaTags/Sessions_VTS_" + lang
      }
    }).then((response) => {
      let metaItemsName = response.data.data.meta_tags.filter((res) => {
        if (res.type === "name") {
          return res
        }
      })
      let metaItemsProperty = response.data.data.meta_tags.filter((res) => {
        if (res.type === "property") {
          return res
        }
      })
      this.setState({metaItemsName, metaItemsProperty})
      let metatittle = response.data.data.page_title;
      this.setState({metatittle: metatittle})
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    window.onpopstate = (e) => {
      this.handleSearch(parseInt(prevProps.match.params.page) - 1);

      // if (parseInt(prevProps.match.params.page) === 1) {
      //   this.props.history.push({
      //     pathname: "/",
      //   });
      // }
    };
  }

  topscroll = (e) => {
    window.scrollTo(0, 0);
  };
  handleSearchInput = (event) => {
    this.setState({
      searchText: event.target.value,
    });
  };
  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.onInput(e);
  };
  onInput = (e) => {
    if (e.target.questionid === "subject") {
      if (e.target.checked) {
        selected_option = this.state.checkedList.filter(
          (CheckedId) => CheckedId !== e.target.value
        );
        selected_option.push(e.target.value);
      } else {
        selected_option = this.state.checkedList.filter(
          (CheckedId) => CheckedId !== e.target.value
        );
      }
      stringData = selected_option
        .map(function (el, idx) {
          return '"' + el + '"';
        })
        .join(",");
      querySubject = `subject=[${stringData}]`;
    }
    this.setState({subjectQuery: querySubject}, () => {
    });
    this.setState({checkedList: selected_option}, () => {
    });

    if (e.target.questionid === "year") {
      if (e.target.checked) {
        selected_option1 = this.state.checkedList1.filter(
          (CheckedId) => CheckedId !== e.target.value
        );

        selected_option1.push(e.target.value);
      } else {
        selected_option1 = this.state.checkedList1.filter(
          (CheckedId) => CheckedId !== e.target.value
        );
      }
      stringData1 = selected_option1
        .map(function (el, idx) {
          return '"' + el + '"';
        })
        .join(",");
      queryYear = `year=[${stringData1}]`;
      this.setState({checkedList1: selected_option1}, () => {
      });
      this.setState({yearQuery: queryYear}, () => {
      });
    }
    let href = "/sessions/" + 1;
    this.props.history.push({
      pathname: href,
    });

    let page1 = 1;
    let limit = 4;
    let lang = "";
    if (
      localStorage.getItem("i18nextLng") === "en" ||
      localStorage.getItem("i18nextLng") === "en-US"
    ) {
      this.setState({lang: "en"});
      lang = "en";
    } else {
      this.setState({lang: "ar"});
      lang = "ar";
    }
    if (selected_option.length === 0 && selected_option1.length === 0) {
      axios.get(process.env.REACT_APP_API_URL, {
        params: {
          // url: `VTS/session/${lang}/index?status=accepted&limit=${limit}&page=${page1}&q=${this.state.searchText}`,
          url: `api/method/variiance.vts_app.api.v2.session.index?lang=${lang}&status=accepted&limit=${limit}&page=${page1}&q=${this.state.searchText}`

        }
      })
        .then((response) => {
          let sessions = response.data.data;
          this.setState({
            sessions,
            results: response.data.data.records,
            total: response.data.meta.total_count,
            current_page: response.data.meta.current_page,
            pag: response.data.meta.pagination,
            isLoading: false,
          });
          this.topscroll();
        });
    } else {
      if (selected_option.length === 0) {
        query = queryYear;
      } else if (selected_option1.length === 0) {
        query = querySubject;
      } else {
        query = querySubject + "&" + queryYear;
      }

      axios.get(process.env.REACT_APP_API_URL, {
        params: {
          // url: `VTS/session/${lang}/index?status=accepted&limit=${limit}&page=${page1}&${query}&q=${this.state.searchText}`,
          url: `api/method/variiance.vts_app.api.v2.session.index?lang=${lang}&status=accepted&limit=${limit}&page=${page1}&${query}&q=${this.state.searchText}`
        }
      })
        .then((response) => {
          let sessions = response.data.data;
          this.setState({
            sessions,
            results: response.data.data.records,
            total: response.data.meta.total_count,
            pag: response.data.meta.pagination,
            current_page: response.data.meta.current_page,
            isLoading: false,
          });
          this.topscroll();
        });
    }
  };

  render() {
    const {t} = this.props;
    let {sessions, subjects, years} = this.state;
    let renderPageNumbers;
    const pageNumbers = [];
    if (this.state.total !== null) {
      for (
        let i = 1;
        i <= Math.ceil(this.state.total / this.state.per_page);
        i++
      ) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers.map((number) => {
        let classes =
          this.state.current_page === number
            ? "page-item page-item-active "
            : "page-item ";

        return (
          <li
            className={classes}
            style={{cursor: "pointer"}}
            key={number}
            onClick={() => this.handleSearch(number)}
          >
            {number}
          </li>
        );
      });
    }

    let singleMetaName = this.state.metaItemsName.map(res => {
      return <meta key={res.key} name={res.key} content={res.value}/>
    })
    let singleMetaProperty = this.state.metaItemsProperty.map(res => {
      return <meta key={res.key} property={res.key} content={res.value}/>
    })


    let subject = subjects.map((sub) => {
      return (
        <div key={sub.name}>
          <Checkbox
            value={sub.name}
            onChange={this.onInput}
            question={sub.name}
            questionid="subject"
          >
            <span className="titleinput">{sub.title}</span>
          </Checkbox>
          <br/>
        </div>
      );
    });
    let year = years.map((sub1) => {
      return (
        <div key={sub1.name}>
          <Checkbox
            value={sub1.name}
            onChange={this.onInput}
            question={sub1.name}
            questionid="year"
          >
            <span className="titleinput">{sub1.title}</span>
          </Checkbox>
          <br/>
        </div>
      );
    });
    let session = sessions.map((res) => {
      return (
        <div className="col-sm-12 my-3 mobcard" key={res.name}>
          <div className="row cardpad">
            <div className="col-lg-9 col-md-9  col-xs-9">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-8 col-xs-8 my-auto ">
                  {res.is_special === 1 ? (
                    <Badge.Ribbon text={t("Special")}>
                      <Link
                        style={{textDecoration: "none", color: "black",}}
                        to={"/singlesession/" + res.name}
                      >
                        {" "}
                        <img
                          className="courseimg"
                          alt={res.subject_image_alt}
                          src={
                            res.subject_image === null || res.subject_image === "" ? img : res.subject_image
                          }
                        />{" "}
                      </Link>
                    </Badge.Ribbon>
                  ) : (
                    <Link
                      style={{textDecoration: "none", color: "black",}}
                      to={"/singlesession/" + res.name}
                    >
                      {" "}
                      <img
                        className="courseimg"
                        alt={res.subject_image_alt}
                        src={res.subject_image === null || res.subject_image === "" ? img : res.subject_image}
                      />{" "}
                    </Link>
                  )}
                </div>
                <div className=" col-lg-9 col-md-8 col-sm-8 col-xs-8 coursecontent ">
                  <Link
                    style={{textDecoration: "none", color: "black",}}
                    to={"/singlesession/" + res.name}
                  >
                    {" "}
                    <h5 style={{fontWeight: "700"}}>{res.title}</h5>
                  </Link>
                  <div>
                    {" "}
                    <Link
                      style={{textDecoration: "none", color: "black"}}
                      to={"/singleteacher/" + res.teacher}
                    >
                      <span
                        className="spann"
                        style={{color: "black", fontWeight: "bold"}}
                      >
                        {res.instructor_name + (res.last_name ? ' ' + res.last_name : '')}
                      </span>
                    </Link>{" "}
                    . <span className="spann">{res.year_name}</span> .{" "}
                    <span className="spann">{res.subject_name}</span>
                  </div>
                  <p
                    className="disc"
                    dangerouslySetInnerHTML={{
                      __html: res.short_description,
                    }}
                  ></p>
                  <div className=" courseprice col-lg-5 col-md-5 col-sm-5 col-xs-5 text-right my-auto">
                    <h5>
                      {res.price} {t("EGP")}{" "}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className=" courseprice col-lg-3 col-md-3 col-sm-3 col-xs-3 text-right my-auto">

              {res.session_started === "started" ? <div className="mb-3 butspan">
                {/*<Button className="buttonexplore1 activecolorspan">*/}
                {/*  <span className="spbtn1 activecolorspan">*/}
                {/*    {res.students_session_count}/{res.session_limit}*/}
                {/*  </span>*/}
                {/*</Button>*/}
                <div className="badge pulsate">Running</div>
              </div> : null}


              <div className="butspan1">
                <img src={star} alt="star"/>{" "}
                {localStorage.getItem("i18nextLng") === "en" ||
                localStorage.getItem("i18nextLng") === "en-US" ? (
                  <span
                    className="spann"
                    style={{color: "black", fontWeight: "bold"}}
                  >
                    {/*{res.time_duration}*/}
                    {/*      <Moment*/}
                    {/*          duration={res.date + " " + res.start_time}*/}
                    {/*          date={res.date + " " + res.end_time}*/}

                    {/*      ></Moment>*/}

                    {moment
                      .duration(res.time_duration, "minutes")
                      .format("h [hrs] m [min]", {trim: "both"})}
                  </span>
                ) : (
                  <span
                    className="spann"
                    style={{color: "black", fontWeight: "bold"}}
                  >
                    {/*{res.time_duration}*/}
                    {/*      <Moment*/}
                    {/*          duration={res.date + " " + res.start_time}*/}
                    {/*          date={res.date + " " + res.end_time}*/}

                    {/*      ></Moment>*/}

                    {moment
                      .duration(res.time_duration, "minutes")
                      .format("h [ساعة] m [دقيقة]", {trim: "both"})}
                  </span>
                )}
              </div>
              <div>
                {" "}
                <p className="disc1 butspan2">
                  {moment(res.date).format("D MMMM YYYY")} ,
                  {moment(res.start_time, "HH:mm:ss").format("hh:mm A")}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    });

    let rendered = this.state.isLoading ? (
      <>
        <div className="container cont">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="example">
                <Spin size="large" style={{margin: "auto"}}/>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <>
        {this.state.sessions.length > 0 ? (
          <div className="container cont">
            <div className="row">
              <div className=" srchhead col-lg-12">
                <h2 className="explore">
                  {t("Explore All")}{" "}
                  <span style={{color: "#ee851d"}}>{t("Sessions")}</span>
                </h2>
                {/* <hr/> */}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-9 mobpage col-md-12">
                <div className="row ">{session}</div>
              </div>
              <div className="col-lg-3 mobpage allfilter paddingdiv">
                <Button className="copy-bg">
                  <span className="spanfilter">{t("Filter by")}</span>
                </Button>
                <h1 className="Sessions-type"> {t("Academic Year")}</h1>
                <div
                  id="scrollbar-1"
                  style={{overflowY: "scroll", height: "170px"}}
                >
                  {year}
                </div>

                <br/>
                <br/>
                <h1 className="Sessions-type">{t("Subject")}</h1>
                <div
                  id="scrollbar-2"
                  style={{overflowY: "scroll", height: "170px"}}
                >
                  {subject}
                </div>
                <br/>
                <br/>

                <form
                  className=" mx-auto searchhome "
                  onSubmit={this.handleSearchSubmit}
                >
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control outlineborder"
                      placeholder={t("Searching For")}
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                      onChange={this.handleSearchInput}
                      value={this.state.searchText || ""}
                    />
                    <button
                      className="btn outlineborder1"
                      type="button"
                      id="button-addon2"
                      onClick={this.handleSearchSubmit}
                    >
                      <img
                        className=" "
                        alt="search icon"
                        src={searchico}
                        width={34}
                        height={34}
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="pb-5">
              <nav aria-label="Page navigation example ">
                <ul className="pagination justify-content-left">
                  {renderPageNumbers}

                  <li
                    className={
                      this.state.current_page === this.state.pag
                        ? "nonee"
                        : "page-item pag"
                    }
                    style={{
                      color: "#908e8e",
                      textDecoration: "none",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.handleSearch(
                        parseInt(this.props.match.params.page) + 1
                      )
                    }
                  >
                    {t("Next")}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        ) : (
          <div className="container cont">
            <div className="row">
              <div className=" srchhead col-lg-12">
                <h2 className="explore">
                  {t("Explore All")}{" "}
                  <span style={{color: "#ee851d"}}>{t("Sessions")}</span>
                </h2>
                {/* <hr/> */}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-9 mobpage col-md-12">
                <div className="row ">
                  <Empty/>
                </div>
              </div>
              <div className="col-lg-3 mobpage allfilter paddingdiv">
                <Button className="copy-bg">
                  <span className="spanfilter">{t("Filter by")}</span>
                </Button>
                <h1 className="Sessions-type">{t("Academic Year")}</h1>
                <div
                  id="scrollbar-3"
                  style={{overflowY: "scroll", height: "170px"}}
                >
                  {year}
                </div>

                <br/>
                <br/>
                <h1 className="Sessions-type">{t("Subject")}</h1>
                <div
                  id="scrollbar-4"
                  style={{overflowY: "scroll", height: "170px"}}
                >
                  {subject}
                </div>
                <br/>
                <br/>

                <form
                  className=" mx-auto searchhome "
                  onSubmit={this.handleSearchSubmit}
                >
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control outlineborder"
                      placeholder={t("Searching For")}
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                      onChange={this.handleSearchInput}
                      value={this.state.searchText || ""}
                    />
                    <button
                      className="btn outlineborder1"
                      type="button"
                      id="button-addon2"
                      onClick={this.handleSearchSubmit}
                    >
                      <img
                        className=" "
                        alt="search icon"
                        src={searchico}
                        width={34}
                        height={34}
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/*<div className="pb-5">*/}
            {/*    <nav aria-label="Page navigation example ">*/}
            {/*        <ul className="pagination justify-content-left">*/}
            {/*            {renderPageNumbers}*/}

            {/*            <li*/}
            {/*                className={this.state.current_page === this.state.pag? "nonee" : "page-item pag"}*/}
            {/*                style={{*/}
            {/*                    color: "#908e8e",*/}
            {/*                    textDecoration: "none",*/}
            {/*                    fontWeight: "600",*/}
            {/*                    cursor: "pointer"*/}
            {/*                }}*/}

            {/*                onClick={() => this.handleSearch(parseInt(this.props.match.params.page) + 1)*/}

            {/*                }*/}
            {/*            >*/}

            {/*                Next*/}
            {/*            </li>*/}
            {/*        </ul>*/}
            {/*    </nav>*/}
            {/*</div>*/}
          </div>
        )}
      </>
    );
    return <div>
      {rendered}
      <Helmet>
        <link href={"https://vts.variiance.com" + this.state.url} rel="canonical"/>
      </Helmet>
      <MetaTags>
        <title>{this.state.metatittle}</title>
        {singleMetaName}
        {singleMetaProperty}
      </MetaTags>
    </div>;
  }
}

export default withRouter(withTranslation()(Sessions));
