import React, {Component} from "react";
import "./pages.css";
import axios from "axios";
import {Translation, withTranslation} from "react-i18next";
import MetaTags from "react-meta-tags";
import logo from "./images/logoformpage.png"
import logo2 from "./images/Teaching-Dark-8.png"
import i18n from "./i18n/i18n";
import Lottie from 'react-lottie-player'
import gif from './images/33886-check-okey-done.json'

class JoinAsTeacher extends Component {
    state = {
        fullname: "",
        message: "",
        subject: "",
        phone: "",
        mail: "",
        age: "",
        number_of_assistants: "",
        visible: false,
        alertt: "",
        errors: {},
        disableButton: false,
        finishFeedback: true,
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
        let {fullname, phone, mail, subject, number_of_assistants, age} = this.state;
        let errors = {};
        let formIsValid = true;
        if (!fullname) {
            formIsValid = false;

            errors.fullname = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        } else if (fullname.length < 3) {
            formIsValid = false;

            errors.fullname = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        } else {
            errors.fullname = ("")
        }

        if (!phone) {
            formIsValid = false;
            errors.phone = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        } else if (typeof phone !== "undefined") {
            if (!phone.match(/^(01|00201|\+201|201)[0|1|2|5]{1}\d{8}/)) {
                formIsValid = false;
                errors.phone = (
                    <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Please enter valid number")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                );
            }
        } else {
            errors.phone = ("")
        }

        if (!mail) {
            formIsValid = false;
            errors.mail = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        } else if (typeof mail !== "undefined") {
            if (
                !mail.match(
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                )
            ) {
                formIsValid = false;
                errors.mail = (
                    <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Please enter valid mail")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                );
            }
        } else {
            errors.mail = ("")
        }

        if (!subject) {
            formIsValid = false;

            errors.subject = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        } else if (subject.length < 3) {
            formIsValid = false;

            errors.subject = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        } else {
            errors.subject = ("")
        }

        if (!age) {
            formIsValid = false;
            errors.age = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        } else {
            errors.age = ("")
        }
        if (!number_of_assistants) {
            formIsValid = false;
            errors.number_of_assistants = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        } else {
            errors.number_of_assistants = ("")
        }

        this.setState({errors: errors});
        return formIsValid;
    };


    dismissAlert = () => {
        this.setState({visible: false});
    };

    show = () => {
        this.setState({visible: true});
    };

    handleValidation = () => {
        let {fullname, phone, mail, subject, age, number_of_assistants} = this.state;
        let errors = {};
        let formIsValid = true;

        if (!fullname) {
            formIsValid = false;
            errors.fullname = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        } else if (fullname.length < 3) {
            formIsValid = false;

            errors.fullname = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        }

        if (!phone) {
            formIsValid = false;
            errors.phone = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        } else if (typeof phone !== "undefined") {
            if (!phone.match(/^(01|00201|\+201|201)[0|1|2|5]{1}\d{8}/)) {
                formIsValid = false;
                errors.phone = (
                    <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Please enter valid number")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                );
            }
        }

        if (!mail) {
            formIsValid = false;

            errors.mail = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        } else if (typeof mail !== "undefined") {
            if (
                !mail.match(
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                )
            ) {
                formIsValid = false;
                errors.mail = (
                    <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Please enter valid mail")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                );
            }
        }

        if (!subject) {
            formIsValid = false;

            errors.subject = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        } else if (subject.length < 3) {
            formIsValid = false;

            errors.subject = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        }

        if (!age) {
            formIsValid = false;
            errors.age = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        }
        if (!number_of_assistants) {
            formIsValid = false;
            errors.number_of_assistants = (
                <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            );
        }


        this.setState({errors: errors});
        return formIsValid;
    };

    handleSubmit = () => {
        if (this.handleValidation() === false) {
        } else {
            this.setState({disableButton: true})
            const newparams = {
                url: "api/resource/Teach With US",
                data: {
                    first_name: this.state.fullname,
                    phone: this.state.phone,
                    mail: this.state.mail,
                    subject: this.state.subject,
                    message: this.state.message,
                    age: this.state.age,
                    number_of_assistants: this.state.number_of_assistants
                },
            };
            axios.post(process.env.REACT_APP_API_URL, newparams)
                .then((response) => {
                    let res = response.status;

                    if (res === 200) {

                        this.setState({
                            fullname: "",
                            subject: "",
                            phone: "",
                            mail: "",
                            message: "",
                            age: "",
                            number_of_assistants: "",
                            disableButton: false,
                            finishFeedback: false
                        });
                        // setTimeout(() => {
                        //   this.setState({ visible: false });
                        // }, 3000);
                    } else {
                        // setTimeout(() => {
                        //   this.setState({ visible: false });
                        // }, 3000);
                    }
                });
        }
    };
    appearForm = () => {
        setTimeout(() => {
            this.setState({finishFeedback: true});
        }, 1000);

    }
    componentDidMount() {
        window.scrollTo(0, 0);

    }


    render() {
        const {t} = this.props;
        let {errors} = this.state;
        return (
            <>
                <div id="formpage">
                    <div className="background">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 ">
                                    <div className="row bigdiv">
                                        <div
                                            className="col-md-7 d-flex flex-column justify-content-center">
                                            <div>
                                                <img src={logo} alt="Variiance Teaching System" className="imgform"/>
                                                <h3 className="headerHome1">
                                                    {t("Variiance Teaching System")}
                                                </h3>
                                                <h1 className="headerHome2">
                                                    {t("For the best")}<br/>
                                                    {t("Teaching Experience")}
                                                </h1>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="modal-body formbg">
                                                <div style={{textAlign: "center"}}>
                                                    <img src={logo2} alt="Variiance Teaching" className="imgpage"/>
                                                </div>
                                                {this.state.finishFeedback ?
                                                    <form className="formVTS" onSubmit={(e) => e.preventDefault()}>

                                                        <div className="group">
                                                            <input
                                                                className="inputMaterial"
                                                                name="mail"
                                                                value={this.state.mail}
                                                                type="text"
                                                                onSelectCapture={this.handleChange}
                                                                onChange={this.handleChange}
                                                            />
                                                            <span className="highlight"></span>
                                                            <span className="bar"></span>
                                                            <label className="label"> {t("Email")}</label>
                                                        </div>
                                                        <span className="err">{errors.mail}</span>

                                                        <div className="group">
                                                            <input
                                                                className="inputMaterial"
                                                                type="text"
                                                                name="fullname"
                                                                value={this.state.fullname}
                                                                onSelectCapture={this.handleChange}
                                                                onChange={this.handleChange}
                                                            />
                                                            <span className="highlight"></span>
                                                            <span className="bar"></span>
                                                            <label className="label"> {t("Name")}</label>
                                                        </div>
                                                        <span className="err">{errors.fullname}</span>
                                                        <div className="group">
                                                            <input
                                                                className="inputMaterial"
                                                                type="number"
                                                                name="age"
                                                                value={this.state.age}
                                                                onSelectCapture={this.handleChange}
                                                                onChange={this.handleChange}
                                                            />
                                                            <span className="highlight"></span>
                                                            <span className="bar"></span>
                                                            <label className="label">{t("age")}</label>
                                                        </div>
                                                        <span className="err">{errors.age}</span>

                                                        <div className="group">
                                                            <input
                                                                className="inputMaterial"
                                                                type="number"
                                                                name="phone"
                                                                value={this.state.phone}
                                                                // required
                                                                onSelectCapture={this.handleChange}
                                                                onChange={this.handleChange}
                                                                pattern="^(01|00201|\+201|201)[0|1|2|5]{1}\d{8}"
                                                            />
                                                            <span className="highlight"></span>
                                                            <span className="bar"></span>
                                                            <label className="label">{t("Mobile")}</label>
                                                        </div>
                                                        <span className="err">{errors.phone}</span>

                                                        <div className="group">
                                                            <input
                                                                className="inputMaterial "
                                                                type="text"
                                                                name="subject"
                                                                value={this.state.subject}
                                                                onSelectCapture={this.handleChange}
                                                                onChange={this.handleChange}
                                                            />
                                                            <span className="highlight"></span>
                                                            <span className="bar"></span>
                                                            <label className="label">{t("Subject")}</label>
                                                        </div>
                                                        <span className="err">{errors.subject}</span>

                                                        <div className="group">
                                                            <input
                                                                className="inputMaterial "
                                                                type="number"
                                                                name="number_of_assistants"
                                                                value={this.state.number_of_assistants}
                                                                onSelectCapture={this.handleChange}
                                                                onChange={this.handleChange}
                                                            />
                                                            <span className="highlight"></span>
                                                            <span className="bar"></span>
                                                            <label className="label">{t("number of assistants")}</label>
                                                        </div>
                                                        <span className="err">{errors.number_of_assistants}</span>

                                                        <div className="d-grid gap-2">
                                                            <button
                                                                className="btn btn-primary btnformsign"
                                                                type="submit"
                                                                onClick={this.handleSubmit}
                                                                disabled={this.state.disableButton}
                                                            >
                                                                {t("Submit")}
                                                            </button>
                                                        </div>
                                                    </form>
                                                    : <Lottie
                                                        loop
                                                        animationData={gif}
                                                        play
                                                        style={{ width: 150, height: 150,margin:"auto" }}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MetaTags>
                    <title>join As A teacher</title>
                </MetaTags>
            </>
        )
    }
}

export default withTranslation()(JoinAsTeacher);
