import React, {Component} from "react";
import img from "./images/img1.png";
import img2 from "./images/3.png";
import img3 from "../pages/images/ava.png";
import {FaFacebookF, FaLinkedin, FaYoutube} from "react-icons/fa";
import {MdVerifiedUser} from "react-icons/md";
import "./pages.css";
import {Spin, Tabs} from "antd";
import About from "./components/about";
import Schedule from "./components/schedule";
import Sessions from "./components/sessions";
import Moment from "react-moment";
import "moment-timezone";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import axios from "axios";
import "moment/locale/ar";
import "moment/locale/en-au";
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import PreviousSessions from "./components/SreviousSessions";

const moment = require("moment");
const {TabPane} = Tabs;

class Singleteacher extends Component {
  state = {
    data: [],
    coursecount: [],
    dataprop: [],
    alldata: [],
    isLoading: true,

  };

  async componentDidMount() {
    this.setState({url: this.props.match.url})

    let set = this.props.match.params.name;
    let lang = "";
    if (
      localStorage.getItem("i18nextLng") === "en" ||
      localStorage.getItem("i18nextLng") === "en-US"
    ) {
      this.setState({lang: "en"});
      lang = "en";
      moment.locale('en-au');
    } else {
      this.setState({lang: "ar"});
      lang = "ar";
      moment.locale('ar');
    }
    // const params = new URLSearchParams();
    // params.append("url", "VTS/teacher/" + lang + "/show/" + set);
    await axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: "VTS/teacher/" + lang + "/show/" + set
        url: `api/method/variiance.vts_app.api.v2.teacher.get_teacher?obj_id=${set}&lang=${lang}`
      }
    })
      .then((response) => {
        let alldata = response.data.data;
        this.setState({alldata});
        this.setState({
          dataprop: <About prop1={this.state.alldata}/>,
        });
        this.setState({
          isLoading: false,
        });
      });

  }

  render() {

    const {t} = this.props;
    window.scrollTo(0, 0);
    let {alldata, dataprop} = this.state;

    let rendered = this.state.isLoading ? (
      <div className="container cont">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="example">
              <Spin size="large" style={{margin: "auto"}}/>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <>
        <div className="container cont2">
          <div className="row mt-5">
            <div className="col-lg-12 Mask">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row g-0">
                    <div className="col-lg-5 text-center my-5">
                      <img
                        className="techerimg"
                        src={
                          alldata.image === null || alldata.image === ""
                            ? img3
                            : alldata.image
                        }
                        alt={alldata.image_alt}
                      />
                    </div>
                    <div className="col-lg-7 mobcent">
                      <h5 className="card-title titlename">
                        {alldata.instructor_name + (alldata.last_name ? ' ' + alldata.last_name : '')}
                        <MdVerifiedUser style={{color: "#00a47c"}}/>
                      </h5>
                      <p
                        className="card-text textname"
                        style={{marginTop: "-2px"}}
                      >
                        {t("Joined in")}{" "}
                        <Moment format="YYYY/MM/DD">{alldata.creation}</Moment>
                      </p>
                      <div className="" style={{marginTop: "35px"}}>
                        {alldata.facebook_link !== "" ? <a className="spicon1 " href={alldata.facebook_link}>
                          <FaFacebookF className="iconsingle"/>
                        </a> : <span className="spicon1 ">
                                                    <FaFacebookF className="iconsingle"/>
                                                </span>}
                        {alldata.youtube_link !== "" ? <a className="spicon1 " href={alldata.youtube_link}>
                          <FaYoutube className="iconsingle"/>
                        </a> : <span className="spicon1 ">
                                                     <FaYoutube className="iconsingle"/>
                                                </span>}
                        {alldata.linkedin_link !== "" ? <a className="spicon1 " href={alldata.linkedin_link}>
                          <FaLinkedin className="iconsingle"/>
                        </a> : <span className="spicon1 ">
                                                    <FaLinkedin className="iconsingle"/>
                                                </span>}


                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3"></div>
                <div className="col-lg-3 mobmargtop">
                  <img className="teachimgbg" src={
                    alldata.teacher_logo === null || alldata.teacher_logo === ""
                      ? img2
                      : alldata.teacher_logo
                  } alt={alldata.teacher_logo_alt}/>
                </div>
              </div>
            </div>
          </div>
          <Tabs tabPosition="top" defaultActiveKey="1">
            <TabPane tab={t("About")} key="1">
              {dataprop}
            </TabPane>
            <TabPane tab={t("Teacher Schedule")} key="2">
              <Schedule/>
            </TabPane>
            <TabPane tab={t("Upcoming Sessions")} key="3">
              <Sessions/>
            </TabPane>
            <TabPane tab={t("Previous Sessions")} key="4">
              <PreviousSessions/>
            </TabPane>
          </Tabs>
        </div>
      </>
    );
    return <div>{rendered}
      <Helmet>
        <link href={"https://vts.variiance.com" + this.state.url} rel="canonical"/>
      </Helmet>
      <MetaTags>
        <title>{alldata.instructor_name + (alldata.last_name ? ' ' + alldata.last_name : '')}</title>
        <meta name="description" content={alldata.description}/>
        <meta content="IE=edge" httpEquiv="X-UA-Compatible"/>
        <meta content="vts" name="author"/>
        <meta property="og:url" content={"https://vts.variiance.com" + this.state.url}/>
        <meta property="og:type" content="website"/>
        <meta property="og:description" content={alldata.description}/>
        <meta property="og:title"
              content={alldata.instructor_name + (alldata.last_name ? ' ' + alldata.last_name : '')}/>
        <meta property="og:image" content={alldata.image}/>
        <meta property="og:image:url" content={alldata.image}/>
        <meta property="og:image:width" content="600"/>
        <meta property="og:image:height" content="400"/>
        <meta property="og:site_name" content="vts"/>
        <meta property="og:image:alt" content={alldata.image_alt}/>
        <meta content={alldata.instructor_name + (alldata.last_name ? ' ' + alldata.last_name : '')}
              name="twitter:title"/>
        <meta content={alldata.image} name="twitter:image"/>
        <meta content="summary" name="twitter:card"/>
        <meta property="twitter:url" content={"https://vts.variiance.com" + this.state.url}/>
        <meta content="@Variianceglobal" name="twitter:site"/>
      </MetaTags>

    </div>;
  }
}

export default withRouter(withTranslation()(Singleteacher));
