import React, {Component} from "react";
import "./pages.css";
import ava from "../pages/images/ava.png";
import {Link} from "react-router-dom";
import {Button, Spin} from "antd";
import axios from "axios";
import {withTranslation} from "react-i18next";
import MetaTags from "react-meta-tags";
import Popup from "./components/popupTelegram";
import keycloak from "../keycloak";
import {AiOutlineCloseCircle} from "react-icons/all";
import 'animate.css';
import {Helmet} from "react-helmet";

class Home extends Component {
  state = {
    alldata: [],
    features: [],
    teachers: [],
    subjects: [],
    features2: [],
    features3: [],
    metaItemsName:[],
    metaItemsProperty:[],
    isLoading: true,
    metatittle:'',
    telegramId:'',
    hide500: false
  };

  hide500Form = () => {
    this.setState({hide500: true})
  }

   getUser = () => {
    const params = {
      // url: "profile/ar/get_user",
      url: "/api/method/variiance.users.api.v2.profile.get_user",
      data: {
        sso_sub: keycloak.tokenParsed.sub,
        student: keycloak.tokenParsed.email,
        name: keycloak.tokenParsed.given_name,
        last_name: keycloak.tokenParsed.family_name,
        student_mobile_number: keycloak.tokenParsed.phone_number ? keycloak.tokenParsed.phone_number : '',
        service_source:"VTS"
      }
    }
    axios.post(process.env.REACT_APP_API_URL, params).then((response) => {
      this.setState({telegramId: response.data.data.telegram_id})
    });
  }

  async componentDidMount() {
     if(keycloak.authenticated){
       await this.getUser()
     }
    let lang = "";
    if (
      localStorage.getItem("i18nextLng") === "en" ||
      localStorage.getItem("i18nextLng") === "en-US"
    ) {
      this.setState({lang: "en"});
      lang = "en";
    } else {
      this.setState({lang: "ar"});
      lang = "ar";
    }
    // const paramsUrl = new URLSearchParams();
    // paramsUrl.append("url", "api/resource/VTS%20Home%20Page/Home-" + lang);

    await axios.get(process.env.REACT_APP_API_URL, {
        params: {
          // url: "api/resource/VTS%20Home%20Page/Home-" + lang
          url: "api/resource/VTS%20Home%20Page/Home-" + lang
        }
      }
    )
      .then((response) => {
        let alldata = response.data.data;
        let features = response.data.data.features;
        let features2 = response.data.data.features_sec2;
        let features3 = response.data.data.features_sec3;
        this.setState({alldata});
        this.setState({features});
        this.setState({features2});
        this.setState({features3});
        this.setState({
          isLoading: false,
        });
      });

    await axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: "VTS/teacher/" + lang + "/index?page=1&limit=4&in_home_page=1",
        url: "api/method/variiance.vts_app.api.v2.teacher.index?page=1&limit=4&in_home_page=1&lang=" + lang
      }
    })
      .then((response) => {
        let teachers = response.data.data;
        let subjects = response.data.data.subjects;
        this.setState({teachers});
        this.setState({subjects});

        this.setState({
          isLoading: false,
        });
      });

    axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: "api/resource/VTSMetaTags/Home_VTS_" + lang
        url: "api/resource/VTSMetaTags/Events_VARIIANCE_" + lang
      }
    }).then((response) => {
      let metaItemsName = response.data.data.meta_tags.filter((res) => {
        if(res.type ==="name"){
          return  res
        }
      })
      let metaItemsProperty = response.data.data.meta_tags.filter((res) => {
        if(res.type ==="property"){
          return res
        }
      })
      this.setState({metaItemsName,metaItemsProperty})
      let metatittle = response.data.data.page_title;
      this.setState({metatittle: metatittle})
    });
  }

  render() {
    const {t} = this.props;
    let {alldata, features, teachers, features2, features3} = this.state;
    let singleMetaName= this.state.metaItemsName.map(res=>{
      return <meta key={res.key} name={res.key} content={res.value}/>
    })
    let singleMetaProperty= this.state.metaItemsProperty.map(res=>{
      return <meta key={res.key} property={res.key} content={res.value}/>
    })
    const feature = features.map((res) => {
      return (
        <div
          className=" cardfeat col-lg-4 col-md-6 my-3 text-center"
          key={res.name}
        >
          <img
            className="imgsec3"
            alt={res.image_feature_alt}
            src={res.image_feature}
          />
          <h3 className="head5 mt-3 text-center">{res.title_feature}</h3>
          <h3 className="head3 mt-3 text-center">{res.description_feature} </h3>
        </div>
      );
    });
    const teacher = teachers.map((res) => {
      let subject = res.subjects.map((resp) => {
        return (
          <Button
            className="buttonexplore1  activecolorspan"
            key={Math.random()}
          >
            <span className="spbtn1 activecolorspan">{resp}</span>
          </Button>
        );
      });
      return (
        <div className="col-lg-3 my-3 col-md-6" key={res.name}>
         <Link to={"/singleteacher/" + res.name}> <img
           className="imgsec2"
           alt={res.image_alt}
           src={res.image === null || res.image === "" ? ava : res.image}
         />
           <h3
             className="head5 mt-3 text-center">{res.instructor_name + (res.last_name ? ' ' + res.last_name : '')}</h3>
         </Link>
          <div className="text-center">{subject}</div>
        </div>
      );
    });

    const feature2 = features2.map((res, index) => {
      return (
        <div className={"gridchild" + index} key={res.name}>
          <div className="Background">
            {" "}
            <div className="circle"></div>
            {" "}
            <h5 className="Interactive-Virtual">{res.name_sec2_feature}</h5>{" "}
          </div>
        </div>
      );
    });
    const feature3 = features3.map((res, index) => {
      return (
        <div className={"gridchild" + index} key={res.name}>
          <div className="Background">
            {" "}
            <div className="circle1"></div>
            {" "}
            <h5 className="Interactive-Virtual">{res.name_sec3_feature}</h5>{" "}
          </div>
        </div>
      );
    });

    let rendered = this.state.isLoading ? (
      <div className="container cont">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="example">
              <Spin size="large" style={{margin: "auto"}}/>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <>
        <div className="container">
          <div id="Home" className="row homesec first">
            <div className="col-lg-6 col-md-6 divone">
              <h4 style={{color: "#ffa900"}}> {alldata.title_sec1} </h4>
              <h1 className="py-3 head1">{alldata.short_description_sec1}</h1>
              <p className="py-3 head3">{alldata.description_sec1} </p>
            </div>
            <div className="col-lg-6 col-md-6">
              <img className="imgsec1" alt={alldata.image_sec1_alt} src={alldata.image_sec1}/>
            </div>
          </div>

          <div id="services" className="row homesec">
            <div className="grid col-lg-6 mb-5 col-md-6">
              <img className="imgsec1" alt={alldata.image_sec2_alt} src={alldata.image_sec2}/>
              {feature2}
            </div>
            <div className="col-lg-6 col-md-6 mb-5 text-center">
              <h2 className="py-3 head2">{alldata.title_sec2} </h2>
              <p className="py-3 head3">{alldata.description_sec2}</p>
              <div
                className="buttonbg"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <Link to="/JoinAsTeacher" className="Lable">{alldata.button_title_sec2}</Link>
              </div>
            </div>
          </div>

          <div className="row homesec">
            <div className="col-lg-12 col-md-12 text-center">
              <h2 className="my-5 head4"> {t("Our Awesome Teachers")}</h2>
            </div>
            {teacher}
            <div className="col-lg-12 my-5">
              {" "}
              <div className="buttonbg">
                <Link to="/Teachers/1" className="Lable">
                  {t("View All Teachers")}
                </Link>
              </div>
              {" "}
            </div>
          </div>

          <div className="row homesec">
            <div className="grid col-lg-6 mb-5 col-md-6">
              <img className="imgsec1" alt={alldata.image_sec3_alt} src={alldata.image_sec3}/>
              {feature3}
            </div>
            <div className=" col-lg-6 col-md-6 mb-5 text-center">
              <h2 className="py-3 head2">{alldata.title_sec3}</h2>
              <p className="py-3 head3">{alldata.description_sec3}</p>
              <div className="buttonbg">
                <a href={alldata.button_url_sec3} className="Lable">
                  {alldata.button_title_sec3}
                </a>
              </div>
            </div>
          </div>

          <div id="features" className="row homesec">
            <div className="col-lg-12 col-md-12 text-center">
                {/*<div className="buttonbgFill">*/}
                {/*  <Link className="Lable3">{t("FEATURES")}</Link>*/}
                {/*</div>*/}
              <h2 className="my-5 head4">{alldata.title_sec4}</h2>
              <p className="py-3 head3">{alldata.description_sec4}</p>
            </div>

            {feature}

            <div className="col-lg-12 my-5">
              {" "}
              {/*<div className="buttonbg">*/}
              {/*  <div onClick={() => keycloak.register()} className="Lable " style={{cursor:"pointer"}}>{alldata.button_title_sec4}</div>*/}
              {/*</div>*/}
              {" "}
            </div>
          </div>
        </div>
        <div id="last" className="row">
          <div className="col-lg-12 col-md-12 py-5 text-center">
            <h2 className="py-3 lastsech2">{alldata.title_sec5}</h2>
            <h4 className="py-3 lastsech4">{alldata.description_sec5}</h4>
            <div className="col-lg-12 my-5">
              {" "}
              <div
                className="buttonbg"
              >
                <Link to="/JoinAsTeacher" className="Lable">{alldata.button_title_sec5}</Link>
              </div>
              {" "}
            </div>
          </div>
        </div>
        {keycloak.authenticated && (this.state.telegramId === null || this.state.telegramId === "") ?
        <Popup/>:null}

        {/*{keycloak && !keycloak.authenticated ?*/}
        {/*    <div*/}
        {/*        className="animate__animated animate__lightSpeedInLeft modalsec2 modal-dialog modal-dialog-2 modal-dialog-centered "*/}
        {/*        style={{display: this.state.hide500 ? 'none' : null}}*/}
        {/*    >*/}
        {/*      <div className="modal-content modal-content-2">*/}
        {/*        <button type="button" className="text-end btn"*/}
        {/*        ><AiOutlineCloseCircle onClick={() => this.hide500Form()} color="#fff" size="35"/>*/}
        {/*        </button>*/}
        {/*        <div className="modal-body">*/}
        {/*          <div className="text-center">*/}
        {/*            <img*/}
        {/*                src="https://vcloud.variiance.com/link/g5CnqcUZyM6uICG5XFq1fLvlqi8Piwo0LhQU9yhb.png"*/}
        {/*                alt="500 logo"*/}
        {/*                className="text-center popupImg"/>*/}
        {/*            <div className="my-5">*/}
        {/*              <p className="popupTxt">{t('popuptxt')}</p>*/}
        {/*              <p className="popupTxt">{t('or')}</p>*/}
        {/*              <p className="popupTxt mb-3">{t('Video conference packages on VLC')}</p>*/}
        {/*              <a href={process.env.REACT_APP_500} target="_blank">*/}
        {/*                <button*/}
        {/*                    className="popupButton"><span*/}
        {/*                    className="popupButtonTxt">{t('popupButton')}</span></button>*/}
        {/*              </a>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div> : null}*/}

      </>
    );
    return <div>
      {rendered}
      <Helmet>
        <link href="https://vts.variiance.com/" rel="canonical"/>
      </Helmet>
      <MetaTags>
        <title>{this.state.metatittle}</title>
        {singleMetaName}
        {singleMetaProperty}
      </MetaTags>
    </div>;
  }
}

export default withTranslation()(Home);
