import React, { Component } from "react";
import img from "../../pages/images/group-20.png";

import { withTranslation } from "react-i18next";

class EmptyT extends Component {
  state = {};

  render() {
    const { t } = this.props;

    return (
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-12 text-center ">
            <img className="picnot" src={img} alt="not found" />
            <h1 className="not_found mt-4">{t("Teacher not found?")}</h1>
            {/* <h6 className="explore_notFound mb-4">
              {t("books_courses")}
              <br />
              {t("Courses and book stays")}
            </h6>
            <Link to="/" className="btn_notFound mt-5">
              {t("Explore new Courses")}
            </Link> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(EmptyT);
