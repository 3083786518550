import React, {Component} from "react";
import img from "../images/8.png";
import img3 from "../images/session.png";
import {Badge, Button} from "antd";
import star from "../images/star.png";
import axios from "axios";
import Moment from "react-moment";
import {Link, withRouter} from "react-router-dom";
import "moment-timezone";
import * as moment from "moment";
import {withTranslation} from "react-i18next";
import "moment/locale/ar";
import "moment/locale/en-au";


class PreviousSessions extends Component {
    constructor(props) {
        super(props);

        this.handleShowMoreSpecial = this.handleShowMoreSpecial.bind(this);
        this.handleShowMoreUpcoming = this.handleShowMoreUpcoming.bind(this);

        this.state = {
            special: [],
            upcoming: [],
            isLoading: true,
            specialLength: 3,
            specialLengthDefaultValue: 3,
            upcomingLength: 3,
            upcomingLengthDefaultValue: 3,
            noItemsspecial:"",
            noItemsUpcoming:""
        };
    }

    handleShowMoreSpecial() {
        this.setState({
            specialLength:
                this.state.specialLength >= this.state.special.length
                    ? this.state.specialLength
                    : this.state.special.length,
        });
    }

    handleShowMoreUpcoming() {
        this.setState({
            upcomingLength:
                this.state.upcomingLength >= this.state.upcoming.length
                    ? this.state.upcomingLength
                    : this.state.upcoming.length,
        });
    }

    handleShowless1 = () => {
        this.setState({
            upcomingLength: 3,
        });
    };
    handleShowless = () => {
        this.setState({
            specialLength: 3,
        });
    };

    async componentDidMount() {
        const {t} = this.props;
        let set = this.props.match.params.name;
        let lang = "";
        if (
            localStorage.getItem("i18nextLng") === "en" ||
            localStorage.getItem("i18nextLng") === "en-US"
        ) {
            this.setState({lang: "en"});
            lang = "en";
            moment.locale('en-au');
        } else {
            this.setState({lang: "ar"});
            lang = "ar";
            moment.locale('ar');
        }
        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                // url: "VTS/teacher/" + lang + "/previous_special_events/" + set
                url: `/api/method/variiance.vts_app.api.v2.teacher.special_events?obj_id=${set}&lang=${lang}`

            }
        })
            .then((response) => {
                let special = response.data.data;
                this.setState({special});
                this.setState({
                    isLoading: false,
                    noItemsspecial: <h5 className="noitems">{t("There are no sessions")} </h5>
                });
            });
        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                // url: "VTS/teacher/" + lang + "/previous_sessions/" + set
                url: `api/method/variiance.vts_app.api.v2.teacher.previous_sessions?obj_id=${set}&lang=${lang}`
            }
        })
            .then((response) => {
                let upcoming = response.data.data;
                this.setState({upcoming});
                this.setState({
                    isLoading: false,
                    noItemsUpcoming: <h5 className="noitems"> {t("There are no sessions")} </h5>
                });
            });
    }

    render() {
        const {t} = this.props;
        let {special, upcoming} = this.state;
        const Items = upcoming.slice(0, this.state.upcomingLength).map((res) => {
            return (
                <div key={Math.random()}>
                    <div className="row cardpad " key={res.name}>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-3 text-center">
                                    {res.subject_image === "" ? (
                                        <img className="upcomingimg" src={img3} alt="session logo"/>
                                    ) : res.subject_image === null ? (
                                        <img className="upcomingimg" src={img3} alt="session logo"/>
                                    ) : (
                                        <img className="upcomingimg" src={res.subject_image} alt={res.subject_image_alt}/>
                                    )}
                                </div>
                                <div className=" col-lg-9 coursecontent ">
                                    <Link
                                        style={{textDecoration: "none", color: "black"}}
                                        to={"/singlesession/" + res.name}
                                    >
                                        {" "}
                                        <p className="namecourse">{res.title}</p>
                                    </Link>
                                    <h5 className="namecourse1">
                                        {res.year_name}. {res.subject_name}
                                    </h5>
                                    <div className=" courseprice col-lg-5 text-right my-auto ">
                                        <h5>
                                            {res.price} {t("EGP")}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" courseprice col-lg-4 text-right">
                            <div className="mb-2 butspan3">
                                <Button className="buttonexplore1 activecolorspan">
                  <span className="spbtn1 activecolorspan">
                    {res.student_count}/{res.session_limit}
                  </span>
                                </Button>
                            </div>
                            <div className="butspan3 mt-2">
                                {" "}
                                <span className="spann">
                  {" "}
                                    <Moment format="D MMMM YYYY">{res.date}</Moment>
                </span>{" "}
                            </div>

                            <div className="butspan1 mt-2">
                                <img src={star} alt="star"/>{" "}

                                {localStorage.getItem("i18nextLng") === "en" ||
                                localStorage.getItem("i18nextLng") === "en-US" ? (
                                    <span
                                        className="spann"
                                        style={{color: "black", fontWeight: "bold"}}
                                    >
                    {moment
                        .duration(res.time_duration, "minutes")
                        .format("h [hrs] m [min]", {trim: "both"})}
                  </span>
                                ) : (
                                    <span
                                        className="spann"
                                        style={{color: "black", fontWeight: "bold"}}
                                    >
                    {moment
                        .duration(res.time_duration, "minutes")
                        .format("h [ساعة] m [دقيقة]", {trim: "both"})}
                  </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="bordercard"></div>
                </div>
            );
        });
        const Items2 = special.slice(0, this.state.specialLength).map((res) => {
            return (
                <div key={Math.random()}>
                    <div className="row cardpad ">
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-3 text-center">
                                    <Badge.Ribbon className="badgg" text={t("Special")}>
                                        {res.subject_image === "" ? (
                                            <img className="spicialimg" src={img3} alt="subject logo"/>
                                        ) : res.subject_image === null ? (
                                            <img className="spicialimg" src={img3} alt="subject logo"/>
                                        ) : (
                                            <img className="spicialimg" src={res.subject_image} alt={res.subject_image_alt}/>
                                        )}
                                    </Badge.Ribbon>
                                </div>
                                <div className=" col-lg-9 coursecontent ">
                                    <Link
                                        style={{textDecoration: "none", color: "black"}}
                                        to={"/singlesession/" + res.name}
                                    >
                                        {" "}
                                        <p className="namecourse3">{res.title}</p>
                                    </Link>
                                    <h5 className="namecourse2">
                                        <Moment format="D MMMM YYYY">{res.date}</Moment>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className=" courseprice col-lg-4 text-right">
                            <h5 className="namecourse4">
                                {res.price} {t("EGP")}{" "}
                            </h5>
                        </div>
                    </div>
                    <div className="bordercard"></div>
                </div>
            );
        });

        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 mobpage col-md-7">
                            <div className="row ">
                                <div className="col-lg-12 col-md-12 my-3 mobcard">
                                    <p className="pseesions">{t("Previous Sessions")}</p>
                                    {
                                        this.state.upcoming.length > this.state.upcomingLengthDefaultValue
                                            ?
                                            this.state.upcoming.length !== this.state.upcomingLength
                                                ? (<span onClick={this.handleShowMoreUpcoming} className="pseesions1">{t("See More")}</span>
                                                ) : (<span onClick={() => this.handleShowless1()} className="pseesions1"> {t("See less")} </span>
                                                ) : null
                                    }
                                    <div style={{clear: "both"}}></div>
                                    {Items}
                                    {upcoming.length===0?this.state.noItemsUpcoming:null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1"></div>
                        <div className="col-lg-4 mobpage col-md-4">
                            <div className="row ">
                                <div className="col-lg-12 col-md-12 my-3 mobcard">
                                    <p className="pseesions">{t("Special Events")}</p>
                                    {
                                        this.state.special.length > this.state.specialLengthDefaultValue
                                            ?
                                            this.state.special.length !== this.state.specialLength ? (
                                                <span onClick={this.handleShowMoreSpecial} className="pseesions1">{t("See More")}</span>
                                            ) : (
                                                <span onClick={() => this.handleShowless()} className="pseesions1">{t("See less")}</span>
                                            ) : null
                                    }
                                    <div style={{clear: "both"}}></div>
                                    {Items2}
                                    {special.length===0?this.state.noItemsspecial:null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(withTranslation()(PreviousSessions));
