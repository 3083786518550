import React from "react";
import AppRouter from "./Router";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import keycloak from "./keycloak";
import "antd/dist/antd.css";
import {ToastContainer} from "react-toastify";
import {Helmet} from "react-helmet"
import Cookies from "universal-cookie";
const cookies = new Cookies();
let dir = cookies.get("direction")
    ? cookies.get("direction")
    : "rtl";
let siteLanguage = ''
if (dir === "ltr") {
  import("./pages/pages.css");
  siteLanguage = 'en'
} else {
  import("./pages/pages.rtl.css");
  siteLanguage = 'ar'
}
const App = () => {
  return (
    <>
      <Helmet htmlAttributes={{
        lang: siteLanguage,
      }}>
        <title lang={siteLanguage}> </title>
      </ Helmet>
      <ReactKeycloakProvider authClient={keycloak}>
        <ToastContainer/>
        <div className="App">
          <AppRouter/>
        </div>
      </ReactKeycloakProvider>
    </>
  );
};

export default App;
