import React from "react"
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle,} from "reactstrap"
import i18n from "../i18n/i18n";
import keycloak from "../../keycloak";
import axios from "axios";
import ReactCountryFlag from "react-country-flag"
// import { IntlContext } from "../../../utility/context/Internationalization"
import Cookies from 'universal-cookie';

const cookies = new Cookies();

let languageSettings = [{"locale": "ar", 'direction': 'rtl', 'lang': 'eg', 'country': 'العربية'}, {
  "locale": "en",
  'direction': 'ltr',
  'lang': 'us',
  'country': 'English'
}];
let locale = cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar';



class Lang extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    country: cookies.get('country'),
    lanug:cookies.get('lang'),
    // lanug: cookies.get('lanug') ? cookies.get('lanug') : 'us',
    direction: cookies.get('direction') ? cookies.get('direction') : "ltr",
    suggestions: [],
    lang:"",
    token: "",

  }
  saveLanguageOfUserSso = (locale, direction) => {
    cookies.set('locale_changed', true)
    cookies.set('locale',locale)
    cookies.set('language sso',locale)
    cookies.set('direction',direction)
      if(keycloak.authenticated){
          let params = {
              "user_sub": keycloak.tokenParsed.sub,
              "language": locale
          }

          axios.post(process.env.REACT_APP_API_BASE_URL + "/user/save_language", params)
              .then(response => {
                  window.location.reload()

              })
      }else{
          window.location.reload()

      }
  }
  // handleSubmit = () => {
  //     const newparams = {
  //         "phone_number": keycloak.tokenParsed.phone_number,
  //         "token": this.state.token,
  //         "sub": keycloak.tokenParsed.sub,
  //         "image": keycloak.tokenParsed.image,
  //     };
  //
  //     // if (!keycloak.tokenParsed.phone_number) {
  //         axios.post("https://api.variiance.com/api/updateUser", newparams)
  //             .then(response => {
  //             })
  //     // }
  //     // localStorage.setItem('user_phone', this.state.phone)
  //     /*this.handleActiveStep(0)*/
  // }


  changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }


  handleDirUpdate = (e) => {

  }

  handleLangDropdown = () =>
      this.setState({langDropdown: !this.state.langDropdown})

  render() {

    return (
        <ul className="nav navbar-nav navbar-nav-user float-right habd ">


          <Dropdown
              tag="li"
              className="dropdown-language nav-item"
              isOpen={this.state.langDropdown}
              toggle={this.handleLangDropdown}
              data-tour="language"
          >
            <DropdownToggle
                tag="a"
                className="nav-link"
            >
              <ReactCountryFlag
                  className="country-flag"
                  countryCode={this.state.lanug}
                  svg
              />
              <span className="d-sm-inline-block m-2 text-capitalize align-middle ml-50">
                                        {this.state.country}
                 </span>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                  tag="a"
                  id="rtl-version"
                  name="rtl-version"
                  onClick={(e) => {
                    if (cookies.get('locale') !== 'en') {
                      this.saveLanguageOfUserSso('en', 'ltr')
                    }


                  }

                  }

              >
                <ReactCountryFlag className="country-flag" countryCode="us" svg/>
                <span className="ml-1 m-2 " style={{color: "#626262"}}>English</span>
              </DropdownItem>
              <DropdownItem
                  tag="a"
                  id="rtl-version"
                  name="rtl-version"
                  onClick={(e) => {
                    if (cookies.get('locale') !== 'ar') {
                      this.saveLanguageOfUserSso('ar', 'rtl')
                    }


                  }

                  }
              >
                <ReactCountryFlag className="country-flag" countryCode="eg" svg/>
                <span className="ml-1 m-2 " style={{color: "#626262"}}>العربية</span>
              </DropdownItem>

            </DropdownMenu>
          </Dropdown>


        </ul>
    )
  }
}

export default Lang
