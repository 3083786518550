import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import img from "../images/session.png";
import img1 from "../images/ava.png";
import Moment from "react-moment";
import axios from "axios";
import keycloak from "../../keycloak";
import {TiDeleteOutline} from "react-icons/all";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

class Announcements extends Component {
  state = {
    isLoading: true,
    Announcements: [],
    Announcement: "",
    session_id: this.props.session_id,
    teacher: this.props.teacher,
    lang: this.props.lang,
    disabled: "disabled",
    posting: false,
  };

  async getAnnouncement() {
    await axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: `api/resource/Announcement?filters=[["session","=","${this.state.session_id}"]]&fields=["*"]&order_by=creation desc&limit_page_length=`
        url: `api/resource/Announcement?filters=[["session","=","${this.state.session_id}"]]`,
        fields: ["*"],
        order_by: 'creation desc'
      }
    }).then((response) => {
      let Announcements = response.data.data;
      this.setState({Announcements});
      this.setState({
        isLoading: false,
        posting: false,
        Announcement: "",
      });
    });
  }

  async componentDidMount() {
    await this.getAnnouncement()
  }

  handleChange = (event) => {
    if (event.target.value !== "") {
      this.setState({disabled: false});
    } else {
      this.setState({disabled: "disabled"});
    }
    this.setState({[event.target.name]: event.target.value});
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    this.setState({disabled: "disabled"});
    this.setState({posting: true});
    const newparams = {
      url: "api/resource/Announcement",
      data: {
        session: this.state.session_id,
        // author_type:
        //   this.state.teacher.email === keycloak.tokenParsed.email
        //     ? "Instructor"
        //     : "Student",
        content: this.state.Announcement,
      },
    };

    axios.post(process.env.REACT_APP_API_URL, newparams)
      .then((response) => {
        if (response.status === 200) {
          this.getAnnouncement()
        }
      });
  };


  deleteAnnouncement = (id) => {
    this.setState({isLoading: true})
    axios.delete(process.env.REACT_APP_API_URL, {
      params: {
        url: "api/resource/Announcement/" + id
      }
    })
      .then(response => {
        this.getAnnouncement()
      })
  }

  render() {
    const {t} = this.props;
    const {teacher, Announcements} = this.state;
    const Items = Announcements.map((res) => {
      return (
        <div key={res.name}>
          <div className=" filecard">
            <div className="file1">
              <div className="instimg">
                <img
                  className="techerimg1"
                  src={
                    teacher.image === null || teacher.image === ""
                      ? img1
                      : teacher.image
                  }
                  width={40}
                  height={40}
                  alt={teacher.image_alt}
                />
              </div>
              <div>
                <h5
                  className="filename"> {teacher.instructor_name + (teacher.last_name ? ' ' + teacher.last_name : '')}</h5>
                <p
                  className="filetxt"
                  dangerouslySetInnerHTML={{__html: res.content}}
                ></p>
              </div>
            </div>

            <div className="file2">
              {(keycloak.authenticated && teacher.email === keycloak.tokenParsed.email) ?
                <TiDeleteOutline style={{cursor: "pointer"}} size={25} color="#f83d3d"
                                 onClick={() => {
                                   confirmAlert({

                                     title: <h1 className="alertH1"> {t("Are you sure ?")}</h1>,
                                     message: <h1 className="text-center alertSize">
                                       {t("you sure about to delete this comment ?")}</h1>,
                                     buttons: [

                                       {
                                         label: t("No"),
                                         className: "no"


                                       }, {
                                         label: t("Delete"),
                                         className: "delete",
                                         onClick: () => {
                                           this.deleteAnnouncement(res.name)
                                         }
                                       }
                                     ]
                                   });
                                 }}
                /> : null}
              {localStorage.getItem("i18nextLng") === "en" ||
              localStorage.getItem("i18nextLng") === "en-US" ? <p className="filetxt">
                  <Moment fromNow ago>
                    {res.creation}
                  </Moment>
                  &nbsp;ago
                </p> :
                <p className="filetxt">
                  منذ&nbsp;
                  <Moment fromNow ago>
                    {res.creation}
                  </Moment>
                </p>}

            </div>
          </div>
          <hr style={{marginInline: "20px"}}/>
        </div>
      );
    });
    return (
      <div className="my-5">
        <hr/>
        <h4 className="head111 mb-5">{t("Announcements")}</h4>
        {this.state.teacher.email === keycloak.tokenParsed.email ?
          <div className="input-group my-5 px-5">
            <div className="input-group-prepend">
              <button
                disabled={this.state.disabled}
                className="btn btn-outline-secondary"
                id="button-addon1"
                onClick={() => this.handleSubmit()}
                value="Submit"
                type="submit"
              >
                {this.state.posting ? (
                  <span>{t("posting")}</span>
                ) : (
                  <span className="plus">+</span>
                )}
              </button>
            </div>
            <input
              type="text"
              className="form-control"
              name="Announcement"
              value={this.state.Announcement}
              placeholder={t("Start typing")}
              aria-label="Example text with button addon"
              aria-describedby="button-addon1"
              onChange={this.handleChange}
              onKeyDown={this._handleKeyDown}
            />
          </div> : null}
        {Items}
      </div>
    );
  }
}

export default withTranslation()(Announcements);
