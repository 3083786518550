import React, {Component} from "react";
import timeico from "./images/star.png";
import icon2 from "./images/icon2.png";
import icon3 from "./images/group-6.png";
import {AiOutlineLeft} from "react-icons/ai";
import {MdVerifiedUser} from "react-icons/md";
import {Link, withRouter} from "react-router-dom";
import {Translation, withTranslation} from "react-i18next";
import "./pages.css";
import {Button, Spin} from "antd";
import axios from "axios";
import "moment-timezone";
import * as moment from "moment";
import keycloak from "../keycloak";
import Publicfile from "../pages/images/file.png";
import img from "../pages/images/session.png";
import img1 from "../pages/images/ava.png";
import Announcements from "./singleSessionComponents/Announcements";
import Files from "./singleSessionComponents/Files";
import Comments from "./singleSessionComponents/Comments";
import i18n from "./i18n/i18n";
import "moment/locale/ar";
import "moment/locale/en-au";
import BookSessionButton from "./singleSessionComponents/BookSessionButton";
import MetaTags from "react-meta-tags";
import {Helmet} from "react-helmet";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

class Singlesession extends Component {

    state = {
        alldata: [],
        files: [],
        teacher: [],
        subjects: [],
        comments: [],
        isLoading: true,
        visible: false,
        disabled_button: false,
        announcement: [],
        posting: false,
        book_session_button: '',
        book_session_class_name: 'buttonbg2',
        teacher_loaded: false
    };


    getUserReferral = (nameOfUser) => {
        const params = {
            // url: "profile/ar/get_user",
            url: "/api/method/variiance.users.api.v2.profile.get_user",
            data: {
                sso_sub: keycloak.tokenParsed.sub,
                student: keycloak.tokenParsed.email,
                name: keycloak.tokenParsed.given_name,
                last_name: keycloak.tokenParsed.family_name,
                student_mobile_number: keycloak.tokenParsed.phone_number ? keycloak.tokenParsed.phone_number : '',
                referred_from: nameOfUser,
                service_source: "VTS"
            }
        }
        return axios.post(process.env.REACT_APP_API_URL, params).then(res => res.data)
    }
    downloadFile = () => {
        if (!keycloak.authenticated) {
            this.notify_notfound('Please login first to download our public file')
        } else {
            window.location.href = this.state.alldata.public_file + "/download"
        }
    }

    notify_notfound = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    async componentDidMount() {
        window.scrollTo(0, 0);
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
        // "some_value"
        if (params.influencer_id !== null && keycloak.authenticated) {
            this.getUserReferral(params.influencer_id)
        }

        this.setState({url: this.props.match.url})

        let set = this.props.match.params.name;
        let mail = "";
        if (keycloak && keycloak.authenticated) {
            mail = keycloak.tokenParsed.email;
        }
        let lang = "";
        if (
            localStorage.getItem("i18nextLng") === "en" ||
            localStorage.getItem("i18nextLng") === "en-US"
        ) {
            this.setState({lang: "en"});
            lang = "en";
            moment.locale('en-au');
        } else {
            this.setState({lang: "ar"});
            lang = "ar";
            moment.locale('ar');
        }
        this.setState({
            book_session_button: <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <span className="Lable">{t("Book This Session")}</span>// will be looked up from namespace ns1
                }
            </Translation>
        })

        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                // url: "VTS/session/" + lang + "/show/" + set + "?student=" + mail
                url: `api/method/variiance.vts_app.api.v2.session.get_session?obj_id=${set}&lang=${lang}&student=${mail}`
            }
        }).then((response) => {
            let alldata = response.data.data;
            console.log(response)
            this.setState({alldata});
            this.setState({
                isLoading: false,
            });
            this.setState({files: <Files session_id={this.state.alldata.name}/>})
        });


        await axios.get(process.env.REACT_APP_API_URL, {
            params: {
                // url: "VTS/session/" + lang + "/session_teacher/" + set
                url: `api/method/variiance.vts_app.api.v2.session.session_teacher?session=${set}&lang=${lang}`
            }
        }).then((response) => {
            let teacher = response.data.data;
            let subjects = response.data.data.subjects;
            this.setState({teacher});
            this.setState({subjects});
            this.setState({
                isLoading: false,
                teacher_loaded: true
            });
            this.setState({announcement: <Announcements session_id={this.state.alldata.name} teacher={teacher}/>})
            this.setState({comments: <Comments session_id={this.state.alldata.name} lang={lang} teacher={teacher}/>})
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.onpopstate = (e) => {
            this.props.history.push({
                pathname: "/sessions/1",
            });
        }
    }

    render() {
        const {t} = this.props;

        let {
            alldata,
            teacher,
            subjects,

        } = this.state;
        const subject = subjects.map((res) => {
            return (
                <Button
                    className="buttonexplore1 my-3  activecolorspan"
                    style={{marginLeft: "0px"}}
                    key={Math.random()}
                >
                    <span className="spbtn1 activecolorspan">{res}</span>
                </Button>
            );
        });

        let rendered = this.state.isLoading ? (
            <div className="container cont">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="example">
                            <Spin size="large" style={{margin: "auto"}}/>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <>

                <div className="container cont">
                    <div id="fffweb" className="row  mt-5">
                        <div className="col-md-6  " id="fl">
                            <Link to="/sessions/1">
                                <div className="buttonbg1 mx-3">
                                    <AiOutlineLeft
                                        style={{color: "#908e8e"}}
                                        className="Lable1"
                                    />
                                </div>
                            </Link>
                            <div>
                                <h5>{alldata.title}</h5>

                                {localStorage.getItem("i18nextLng") === "en" ||
                                localStorage.getItem("i18nextLng") === "en-US" ? (
                                    <p className="butspan" style={{color: "#908e8e", fontWeight: "500"}}>
                                        {alldata.year_name} . {alldata.subject_name} &nbsp;
                                        <span>
                      <img
                          src={timeico}
                          alt="time icon"
                          width={17}
                          height={17}
                      />
                    </span>
                                        &nbsp;
                                        {moment
                                            .duration(alldata.time_duration, "minutes")
                                            .format("h [hr]  m [min]", {trim: "both"})}
                                    </p>
                                ) : (
                                    <p className="butspan" style={{color: "#908e8e", fontWeight: "500"}}>
                                        {alldata.year_name} . {alldata.subject_name} &nbsp;
                                        <span>
                      <img
                          src={timeico}
                          alt="time icon"
                          width={17}
                          height={17}
                      />
                    </span>
                                        &nbsp;
                                        {moment
                                            .duration(alldata.time_duration, "minutes")
                                            .format("h [ساعة]  m [دقيقة]", {trim: "both"})}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6" id="fl1">
                            <div>
                                <h4>
                                    {alldata.price} {t("EGP")}
                                </h4>
                            </div>
                            {keycloak && !keycloak.authenticated ?
                                <BookSessionButton status={this.state.alldata.student_status}
                                                   sessionData={this.state.alldata}
                                                   teacher={this.state.teacher}/> : keycloak && keycloak.authenticated && this.state.teacher_loaded && this.state.teacher.email !== keycloak.tokenParsed.email ?
                                    <BookSessionButton status={this.state.alldata.student_status}
                                                       sessionData={this.state.alldata}
                                                       teacher={this.state.teacher}/> : null}
                        </div>
                    </div>
                    <div id="fffmob" className="row  mt-5">
                        <div className="col-md-6  " id="fl12">
                            <div className="text-center">
                                <h5>{alldata.title}</h5>
                                {localStorage.getItem("i18nextLng") === "en" ||
                                localStorage.getItem("i18nextLng") === "en-US" ? (
                                    <p className="butspan" style={{color: "#908e8e", fontWeight: "500"}}>
                                        {alldata.year_name} . {alldata.subject_name} &nbsp;
                                        <span>
                      <img
                          src={timeico}
                          alt="time icon"
                          width={17}
                          height={17}
                      />
                    </span>
                                        &nbsp;
                                        {moment
                                            .duration(alldata.time_duration, "minutes")
                                            .format("h [hr]  m [min]", {trim: "both"})}
                                    </p>
                                ) : (
                                    <p className="butspan" style={{color: "#908e8e", fontWeight: "500"}}>
                                        {alldata.year_name} . {alldata.subject_name} &nbsp;
                                        <span>
                      <img
                          src={timeico}
                          alt="time icon"
                          width={17}
                          height={17}
                      />
                    </span>
                                        &nbsp;
                                        {moment
                                            .duration(alldata.time_duration, "minutes")
                                            .format("h [ساعة]  m [دقيقة]", {trim: "both"})}
                                    </p>
                                )}
                                <h4 className="mt-2">
                                    {alldata.price} {t("EGP")}
                                </h4>
                            </div>
                        </div>
                        <div className="col-md-6  " id="fl2">
                            <Link to="/sessions/1">
                                <div className="buttonbg1 mx-3">
                                    <AiOutlineLeft
                                        style={{color: "#908e8e"}}
                                        className="Lable1"
                                    />
                                </div>
                            </Link>
                            {keycloak && !keycloak.authenticated ?
                                <BookSessionButton status={this.state.alldata.student_status}
                                                   sessionData={this.state.alldata}
                                                   teacher={this.state.teacher}/> : keycloak && keycloak.authenticated && this.state.teacher_loaded && this.state.teacher.email !== keycloak.tokenParsed.email ?
                                    <BookSessionButton status={this.state.alldata.student_status}
                                                       sessionData={this.state.alldata}
                                                       teacher={this.state.teacher}/> : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            {alldata.subject_image === null || alldata.subject_image === "" ?
                                <div className="mt-3"></div>
                                : <div className="mt-3">
                                    <img
                                        className="firstimg"
                                        src={alldata.subject_image}
                                        // src={
                                        //   alldata.img === null || alldata.img === ""
                                        //     ? img
                                        //     : alldata.img
                                        // }
                                        alt={alldata.subject_image_alt}
                                    />
                                </div>}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <div className="my-5">
                                <h4 className="head111">{t("About")}</h4>
                                <p
                                    className="text111"
                                    dangerouslySetInnerHTML={{__html: alldata.description}}
                                ></p>
                            </div>
                            <hr/>
                            <div className="my-5">
                                <h4 className="head111 pb-3">{t("Session Goals")}</h4>
                                <div dangerouslySetInnerHTML={{__html: alldata.goals}}></div>
                                {/* <div className="goals"> <div className="circle2"></div> <div> <p className="text111">This course is designed to develop interpersonal skills</p></div></div>
                                <div className="goals"> <div className="circle2"></div> <div><p className="text111">delivery of successful presentations.Whether you’re looking for general tips on effective public</p> </div></div>
                                <div className="goals"> <div className="circle2"></div> <div><p className="text111"> this course shows you how to present memorably and with impact, through a series of techniques </p> </div> </div>
                                <div className="goals"> <div className="circle2"></div>  <div><p className="text111">Practical activities that helps you to overcome fear, enhance confidence a</p> </div> </div> */}
                            </div>

                            {this.state.alldata.student_status === 'Owned' ||
                            (keycloak.authenticated && teacher.email === keycloak.tokenParsed.email) ?
                                this.state.announcement : null
                            }

                            {this.state.alldata.student_status === 'Owned' ||
                            (keycloak.authenticated && teacher.email === keycloak.tokenParsed.email) ?
                                this.state.files : null
                            }

                            {this.state.alldata.student_status === 'Owned' ||
                            (keycloak.authenticated && teacher.email === keycloak.tokenParsed.email) ?
                                this.state.comments : null
                            }

                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="carddd my-5">
                                <p>{t("Teacher")}</p>
                                <div className=" my-3 cardhead">
                                    <div>
                                        <Link to={"/singleteacher/" + teacher.name}>
                                            <img
                                                className="techerimg1"
                                                src={
                                                    teacher.image === null || teacher.image === ""
                                                        ? img1
                                                        : teacher.image
                                                }
                                                width={60}
                                                height={60}
                                                alt={teacher.image_alt}
                                            />
                                        </Link>
                                    </div>
                                    <div style={{paddingRight: "10px", paddingLeft: "10px"}}>
                                        <Link to={"/singleteacher/" + teacher.name}>
                                            <h5 className="head111"> {teacher.instructor_name + (teacher.last_name ? ' ' + teacher.last_name : '')}
                                                <MdVerifiedUser
                                                    style={{color: "#00a47c", fontSize: "22"}}
                                                /></h5></Link>
                                        <p className="text111" style={{marginTop: "-2px"}}>
                                            {teacher.short_description}
                                        </p>
                                    </div>
                                </div>
                                {subject}
                                <hr/>

                                <div className=" mb-2 mt-5 ">
                                    <div className="file1">
                                        <div className="instimg">
                                            <img src={icon2} alt="documents" width={50} height={50}/>
                                        </div>
                                        <div>
                                            <h5 className="filename">{t("Identity verified")}</h5>
                                            <p className="filetxt">{t("All documents verified")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className=" my-2 ">
                                    <div className="file1">
                                        <div className="instimg">
                                            <img src={icon3} alt="user icon" width={50} height={50}/>
                                        </div>
                                        <div>
                                            <h5 className="filename">
                                                {teacher.students_count} {t("Students")}{" "}
                                            </h5>
                                            <p className="filetxt">{t("Students")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.alldata.public_file !== null && this.state.alldata.public_file !== "" ?
                                <div style={{cursor: "pointer", textAlign: "center"}} onClick={this.downloadFile}>
                                    <h4 className="head111">{t("Download Session File")}</h4>
                                    <img className="publicfile my-2" src={Publicfile}/>
                                    <p className="filetxt" style={{color: "rgb(255, 169, 0)"}}
                                       s>{this.state.alldata.public_file_title}</p>
                                </div> : null
                            }
                        </div>
                    </div>
                </div>

            </>
        );
        return <div>{rendered}
            <Helmet>
                <link href={"https://vts.variiance.com" + this.state.url} rel="canonical"/>
            </Helmet>
            <MetaTags>
                <title>{alldata.title}</title>
                <meta name="description" content={alldata.description}/>
                <meta content="IE=edge" httpEquiv="X-UA-Compatible"/>
                <meta content="vts" name="author"/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={"https://vts.variiance.com" + this.state.url}/>
                <meta property="og:description" content={alldata.description}/>
                <meta property="og:title" content={alldata.title}/>
                <meta property="og:image" content={alldata.subject_image}/>
                <meta property="og:image:url" content={alldata.subject_image}/>
                <meta property="og:image:width" content="600"/>
                <meta property="og:image:height" content="400"/>
                <meta property="og:site_name" content="vts"/>
                <meta property="og:image:alt" content={alldata.subject_image_alt}/>
                <meta content={alldata.title} name="twitter:title"/>
                <meta content={alldata.subject_image} name="twitter:image"/>
                <meta content="summary" name="twitter:card"/>
                <meta property="twitter:url" content={"https://vts.variiance.com" + this.state.url}/>
                <meta content="@Variianceglobal" name="twitter:site"/>
            </MetaTags>
        </div>;
    }
}

export default withRouter(withTranslation()(Singlesession));
