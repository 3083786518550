import React from "react";
import {Link} from "react-router-dom";
import img from "./images/Teaching-Dark-8.png";
import keycloak from "./../keycloak";
import {NavHashLink} from "react-router-hash-link";
import "./pages.css";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,} from "reactstrap";
import * as Icon from "react-feather";
import {withTranslation} from "react-i18next";

// import avatar1 from "./images/256-512.png";
import avatar1 from "../pages/images/ava.png";
import Lang from "./components/lang";
import {IoMdArrowDropdown} from "react-icons/all";

const NavBar = ({t, i18n, match}) => {

  const onRemoveClick = (e) => {
    document.getElementById("navbarSupportedContent").classList.remove("show");
  };

  // const topscroll = (e) => {
  //   window.scrollTo(0, 0);
  // };
  // let { page } = useParams();
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light  navbar-head fixed-top">
        <div className="container-fluid navcont">
          <Link className="navbar-brand" to="/">
            <img className="img-navbar" alt="vts logo" src={img}/>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
            style={{backgroundColor: "white"}}
          >
            <ul className="navbar-nav customclass ">
              <li className="nav-item">
                <NavHashLink
                  activeClassName="current"
                  className="nav-link nav-link1 "
                  aria-current="page"
                  // onClick={topscroll}
                  to="/#Home"
                  onClick={onRemoveClick}
                >
                  {t("Home")}
                </NavHashLink>
              </li>
              <li className="nav-item">
                <NavHashLink
                  activeClassName="current"
                  className="nav-link nav-link1 "
                  aria-current="page"
                  to="/#services"
                  onClick={onRemoveClick}
                >
                  {t("Services")}
                </NavHashLink>
              </li>
              <li className="nav-item">
                <NavHashLink
                  activeClassName="current"
                  className="nav-link nav-link1 "
                  aria-current="page"
                  to="/#features"
                  onClick={onRemoveClick}
                >
                  {t("Features")}
                </NavHashLink>
              </li>
              <li className="nav-item">
                <NavHashLink
                  activeClassName="current"
                  className="nav-link nav-link1 "
                  aria-current="page"
                  to="/Teachers/1"
                  onClick={onRemoveClick}
                  // onClick={topscroll}
                >
                  {t("Teachers")}
                </NavHashLink>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link nav-link1 "
                  to={"/sessions/1"}
                  onClick={onRemoveClick}
                  // onClick={topscroll}
                >
                  {t("Sessions")}
                </Link>
              </li>

              {/* <li  className="nav-item"><Link className="nav-link nav-link1" onClick={ () =>keycloak && !keycloak.authenticated ? keycloak.login():keycloak.logout()}>{keycloak && !keycloak.authenticated ?"Login":"Logout"}</Link></li> */}
            </ul>

            {/*<div className="bg">*/}
            {/*  <button*/}
            {/*    className="Labletech"*/}
            {/*    data-bs-toggle="modal"*/}
            {/*    data-bs-target="#exampleModal"*/}
            {/*  >*/}
            {/*    {t("Become a Teacher")}*/}
            {/*  </button>*/}
            {/*</div>*/}

            {keycloak && !keycloak.authenticated ? (
              <div className="bg-btn1">
                <button
                  className="Label-btn1"
                  onClick={() =>
                    keycloak && !keycloak.authenticated
                      ? keycloak.login()
                      : keycloak.logout()
                  }
                >
                  {keycloak && !keycloak.authenticated
                    ? t("Login")
                    : t("Logout")}
                </button>
              </div>
            ) : (
              <ul
                className="ulmob nav navbar-nav navbar-nav-user float-right"
                style={{}}
              >
                <UncontrolledDropdown tag="li" className="dropdown-user ">
                  <DropdownToggle
                      tag="a"
                      className="nav-link dropdown-user-link styleUL"
                  >
                    <span data-tour="user">
                      <img
                          className="pic ml-5 "
                          alt="profile"
                          style={{borderRadius: "100%"}}
                          src={
                            keycloak.tokenParsed.image
                                ? keycloak.tokenParsed.image
                                : avatar1
                          }
                      />
                    </span>
                    <span className="mx-1" style={{color:"#908e8e"}} > {keycloak.tokenParsed.given_name} <IoMdArrowDropdown/> </span>

                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem tag="a" href={process.env.REACT_APP_PROFILE}>
                      <Icon.User size={14} className="mr-50"/>
                      <span className="align-middle m-2">
                        {" "}
                        {keycloak.tokenParsed.name}{" "}
                      </span>
                    </DropdownItem>
                    {keycloak.tokenParsed.resource_access.website.roles.includes("instructors") === true ?
                      <DropdownItem
                        tag="a"
                        href={process.env.REACT_APP_INSTRUCTOR+'/VtsHome'}
                      >
                        <Icon.Linkedin size={14} className="mr-50"/>
                        <span className="align-middle m-2">
                        {" "}
                          {t("instructor dashboard")}
                      </span>
                      </DropdownItem> : null}
                    <DropdownItem
                      tag="a"
                      href={process.env.REACT_APP_PARTICIPANT+"/vtshome"}
                    >
                      <Icon.UserCheck size={14} className="mr-50"/>
                      <span className="align-middle m-2">
                        {" "}
                        {t("participant dashboard")}{" "}
                      </span>
                    </DropdownItem>
                    <DropdownItem tag="a" href={process.env.REACT_APP_PAYMENT}>
                      <Icon.CreditCard size={14} className="mr-50"/>
                      <span className="align-middle m-2">
                        {" "}
                        {t("payment portal")}{" "}
                      </span>
                    </DropdownItem>

                    <DropdownItem tag="a" href={process.env.REACT_APP_COURSES}>
                      <Icon.BookOpen size={14} className="mr-50"/>
                      <span className="align-middle m-2">
                        {" "}
                        {t("Vol")}{" "}
                      </span>
                    </DropdownItem>

                    <DropdownItem tag="a" href={process.env.REACT_APP_VLC}>
                      <Icon.Wifi size={14} className="mr-50"/>
                      <span className="align-middle m-2">
                        {" "}
                        {t("VLC")}{" "}
                      </span>
                    </DropdownItem>

                    <DropdownItem divider/>

                    <DropdownItem
                      tag="a"
                      onClick={() => {
                        localStorage.removeItem('student_id')
                        keycloak.logout()
                      }}
                      className="btn-link align-middle"
                    >
                      <Icon.Power size={14} className="mr-50"/>
                      <span
                        className=" align-middle"
                      >
                        {" "}
                        {t("Logout")}{" "}
                      </span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ul>
            )}
            {/* <div className="langchane" onClick={onRemoveClick}>
      <a href="#" style={{cursor:"pointer" }}><GlobalOutlined style={{ fontSize: '17px',color:"#33333a",cursor:"pointer" }}/>
      <a href="#"> <label style={{cursor:"pointer" }} className="Label-lang">English</label></a></a>
      </div> */}
            <div className="ulmob">
              <Lang/>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default withTranslation()(NavBar);
