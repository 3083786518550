import React, {Component} from "react";
import axios from "axios";
// import keycloak from "../../keycloak";
import i18n from "../i18n/i18n";
import {Alert} from "reactstrap";
import {withTranslation} from "react-i18next";
import {Translation} from "react-i18next";
import img from "../images/t_logo.svg"


class Popup extends Component {




    render() {
        const {t} = this.props;

        return (
            <a href={process.env.REACT_APP_TELEGRAM_BOT} target="_blank">
            <div className="modalsec">
                <div className="my-2">
                    <img className="m-auto d-block" src={img} alt="telegram" width="40px" height="40px"/>
                    <p className="modaltxt">{t("Subscribe in telegram")}</p>
                </div>
            </div>
            </a>
        );
    }
}

export default withTranslation()(Popup);
