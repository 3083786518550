import React from "react";
import "./App.css";
import {Route, Router, Switch} from "react-router-dom";
// import keycloak from './keycloak'
//pages
import Sessions from "./pages/allsession.js";
import Home from "./pages/Home.js";
import Teachers from "./pages/Teachers.js";
import Singlesession from "./pages/singlesession.js";
import Singleteacher from "./pages/singleteacher";
import {useKeycloak} from "@react-keycloak/web";
import Footer from "./pages/footer.js";
import {history} from "./history";
import NavBar from "./pages/NavBar.js";
import JoinAsTeacher from "./pages/JoinAsTeacher.js"
import {useTranslation} from "react-i18next";
import axios from "axios";
import keycloak from "./keycloak";
import Cookies from "universal-cookie";
import PopupForm from "./pages/components/popupForm";
import {ExamplePage} from "./pages/components/chatBot";
import NotFound from "./pages/components/Error404";
import EmptyRedirectionRefallerGeneral from "./pages/EmptyRedirectionRefallerGeneral";

const cookies = new Cookies();

// const guardAuth = (Component) => () => {

//   return keycloak && !keycloak.authenticated ? (

//     keycloak.login()
//     ) : (
//     <Component />
//   );
// };
function getUser() {
    const params = {
        // url: "profile/ar/get_user",
        url: "/api/method/variiance.users.api.v2.profile.get_user",
        data: {
            sso_sub: keycloak.tokenParsed.sub,
            student: keycloak.tokenParsed.email,
            name: keycloak.tokenParsed.given_name,
            last_name: keycloak.tokenParsed.family_name,
            student_mobile_number: keycloak.tokenParsed.phone_number ? keycloak.tokenParsed.phone_number : '',
            service_source:"VTS"
        }
    }
    axios.post(process.env.REACT_APP_API_URL, params).then((response) => {
        localStorage.setItem('student_id', response.data.data.name)
    });
}
const guardAuth = (Component) => () => {
    return keycloak && !keycloak.authenticated ? (
        keycloak.login()
    ) : (
        <>
            <Component/>
        </>
    );
};

const AppRouter = () => {
    const {i18n} = useTranslation();
    document.body.dir = i18n.dir();

    const {initialized} = useKeycloak();
    if (!initialized) {
        return true;
    } else {
        if (keycloak.authenticated) {
            if (cookies.get('locale_changed')) {
                cookies.set('language sso', cookies.get('locale'))
                cookies.set('locale_changed', "false")
            } else {
                cookies.set('language sso', keycloak.tokenParsed.language != 'undefined' ? cookies.get('language sso') : 'ar')
            }
            let languageSettings = [{
                "locale": "ar",
                'direction': 'rtl',
                'lang': 'eg',
                'country': 'العربية'
            }, {"locale": "en", 'direction': 'ltr', 'lang': 'us', 'country': 'English'}];

            let locale = cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar';
            cookies.set('lang', languageSettings.find(x => x.locale === locale).lang)
            cookies.set('country', languageSettings.find(x => x.locale === locale).country)
            cookies.set('direction', languageSettings.find(x => x.locale === locale).direction)
            // cookies.set('locale',cookies.get('language sso') && cookies.get('language sso') != 'undefined' ? cookies.get('language sso') : 'ar')

        }
    }
    if (!localStorage.getItem('student_id') && keycloak.authenticated) {
        getUser()
    }
    if (!keycloak.authenticated && localStorage.getItem('student_id')) {
        localStorage.removeItem('student_id')
    }
    return (
        <Router history={history}>
            <NavBar/>
            <Switch>
                <Route exact path="/">
                    <Home/>
                    <PopupForm/>
                </Route>

                <Route path="/sessions/:page">
                    <Sessions/>
                    <PopupForm/>
                </Route>

                <Route path="/Teachers/:page">
                    <Teachers/>
                    <PopupForm/>
                </Route>

                <Route path="/singleteacher/:name">
                    <Singleteacher/>
                    <PopupForm/>
                </Route>

                <Route path="/singlesession/:name">
                    <Singlesession/>
                    <PopupForm/>
                </Route>

                <Route path="/JoinAsTeacher">
                    <JoinAsTeacher/>
                </Route>
                <Route path="/vtsReferral/:id">
                    {guardAuth(EmptyRedirectionRefallerGeneral)}
                </Route>
                <Route component ={NotFound}/>
            </Switch>
            <Footer/>
            {/* <ExamplePage/> */}
        </Router>
    );
};
export default AppRouter;
