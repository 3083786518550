import React, {Component} from "react";
import {Button} from "antd";
import {withTranslation} from "react-i18next";
import ReactPlayer from 'react-player'
import img3 from "../images/ava.png";

class About extends Component {
    state = {
        alldata: this.props.prop1,
    };

    render() {
        const {t} = this.props;
        let {alldata} = this.state;
        const Subjects = alldata.subjects.map((res) => {
            return (
                <Button
                    className="buttonexplore1  activecolorspan"
                    style={{marginLeft: "0px"}}
                    key={Math.random()}
                >
                    <span className="spbtn1 activecolorspan">{res.subjects}</span>
                </Button>
            );
        });
        return (
            <>
                {
                    alldata.video_url === null || alldata.video_url === ""
                        ? <div className="row">
                            {/* <div className="col-lg-12 col-md-12">
                    <img src={img} alt="image1" className="firstimg" />
                </div>  */}

                            <div className="col-lg-12 col-md-12 px-3 pb-5">
                                <p className="head">{t("About This Teacher")}</p>
                                <p className="headpar">{alldata.description} </p>
                                <p className="head">{t("Subjects")}</p>
                                {Subjects}
                            </div>

                        </div> :
                        <div className="row">
                            {/* <div className="col-lg-12 col-md-12">
                    <img src={img} alt="image1" className="firstimg" />
                </div>  */}

                            <div className="col-lg-8 col-md-8 px-3 mb-2">
                                <p className="head">{t("About This Teacher")}</p>
                                <p className="headpar">{alldata.description} </p>
                                <p className="head">{t("Subjects")}</p>
                                {Subjects}
                            </div>
                            <div className="col-lg-4 col-md-4 mb-2">
                                <div className="mb-5">
                                    <ReactPlayer url={alldata.video_url} controls={true} pip={true} className="vid"/>
                                </div>
                            </div>
                        </div>
                }

            </>

        );
    }

}

export default withTranslation()(About);
