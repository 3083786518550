import React, {Component} from "react";
import "./pages.css";
import {Button, Checkbox, Spin} from "antd";
import img from "../pages/images/ava.png";
import {Link, withRouter} from "react-router-dom";
import axios from "axios";
import EmptyT from "../pages/components/emptyT";
import {withTranslation} from "react-i18next";
import searchico from "./images/searchico.png";
import MetaTags from "react-meta-tags";
import {Helmet} from "react-helmet";

let selected_option = [];
let stringData = "";
let querySubject = "";
let query = "";

class Teachers extends Component {
  state = {
    teachers: [],
    pag: 0,
    subjects: [],
    searchText: "",
    total: null,
    per_page: 12,
    current_page: 1,
    isLoading: true,
    selectedOption: [],
    checkedList: [],
    questinId: "",
    metaItemsName: [],
    metaItemsProperty: [],
    url: "",
    metatittle: ''
  };
  handleSearch = (e) => {
    let page = e || this.props.match.params.page || 1;
    let limit = 12;
    let href = `/Teachers/${page}`;
    this.props.history.push({
      pathname: href,
    });
    if (selected_option.length === 0) {
      query = "";
    }
    let lang = "";
    if (
      localStorage.getItem("i18nextLng") === "en" ||
      localStorage.getItem("i18nextLng") === "en-US"
    ) {
      this.setState({lang: "en"});
      lang = "en";
    } else {
      this.setState({lang: "ar"});
      lang = "ar";
    }
    axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: `VTS/teacher/${lang}/index?limit=${limit}&page=${page}&${query}&q=${this.state.searchText}`
        url: `api/method/variiance.vts_app.api.v2.teacher.index?lang=${lang}&limit=${limit}&page=${page}&${query}&q=${this.state.searchText}`
      }
    })
      .then((response) => {
        let teachers = response.data.data;
        this.setState({
          teachers,
          results: response.data.data.records,
          total: response.data.meta.total_count,
          current_page: response.data.meta.current_page,
          pag: response.data.meta.pagination,
          isLoading: false,
        });
        this.topscroll();
      });
  };

  componentDidMount() {
    this.setState({url: this.props.match.url})
    selected_option = [];
    let page = this.props.match.params.page || 1;
    let href = `/Teachers/${page}`;
    this.props.history.push({
      pathname: href,
    });
    let lang = "";
    if (
      localStorage.getItem("i18nextLng") === "en" ||
      localStorage.getItem("i18nextLng") === "en-US"
    ) {
      this.setState({lang: "en"});
      lang = "en";
    } else {
      this.setState({lang: "ar"});
      lang = "ar";
    }
    axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: `VTS/teacher/${lang}/index?limit=12&page=${page}`
        url: `api/method/variiance.vts_app.api.v2.teacher.index?lang=${lang}&limit=12&page=${page}`
      }
    })
      .then((response) => {
        let teachers = response.data.data;
        this.setState({
          teachers,
          results: response.data.data.records,
          total: response.data.meta.total_count,
          current_page: response.data.meta.current_page,
          pag: response.data.meta.pagination,
          isLoading: false,
        });
        this.topscroll();
      });
    axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: `VTS/session/${lang}/subjects`
        url: `api/method/variiance.vts_app.api.v2.session.subjects?lang=${lang}`
      }
    })
      .then((response) => {
        let subjects = response.data.data;
        this.setState({subjects});
      });

    axios.get(process.env.REACT_APP_API_URL, {
      params: {
        // url: "api/resource/VTSMetaTags/Teachers_VTS_" + lang
        url: "api/resource/VTSMetaTags/Teachers_VTS_" + lang

      }
    }).then((response) => {
      let metaItemsName = response.data.data.meta_tags.filter((res) => {
        if (res.type === "name") {
          return res
        }
      })
      let metaItemsProperty = response.data.data.meta_tags.filter((res) => {
        if (res.type === "property") {
          return res
        }
      })
      this.setState({metaItemsName, metaItemsProperty})
      let metatittle = response.data.data.page_title;
      this.setState({metatittle: metatittle})

    });

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (prevProps.match.params.page !== this.props.match.params.page) {
      this.handleSearch(this.props.match.params.page);

    }
    window.onpopstate = (e) => {
      this.handleSearch(parseInt(prevProps.match.params.page) - 1);
      if (parseInt(prevProps.match.params.page) === 1) {
        // this.props.history.goBack()

      }
    };
  }

  topscroll = (e) => {
    window.scrollTo(0, 0);
  };
  handleSearchInput = (event) => {
    this.setState({
      searchText: event.target.value,
    });
  };
  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.onInput(e);
  };
  onInput = (e) => {
    // this.setState({current_page:1})
    if (e.target.checked) {
      //if checked (true), then add this id into checkedList
      selected_option = this.state.checkedList.filter(
        (CheckedId) => CheckedId !== e.target.value //  filter(checkID=>{CheckedId !== id}) remove {}
      );
      selected_option.push(e.target.value);
    } //if not checked (false), then remove this id from checkedList
    else {
      selected_option = this.state.checkedList.filter(
        (CheckedId) => CheckedId !== e.target.value //  filter(checkID=>{CheckedId !== id}) remove {}
      );
    }
    this.setState({
      checkedList: selected_option,
    });
    let href = `/Teachers/1`;
    this.props.history.push({
      pathname: href,
    });
    let page1 = 1;
    let limit = 12;
    let lang = "";
    if (
      localStorage.getItem("i18nextLng") === "en" ||
      localStorage.getItem("i18nextLng") === "en-US"
    ) {
      this.setState({lang: "en"});
      lang = "en";
    } else {
      this.setState({lang: "ar"});
      lang = "ar";
    }
    if (selected_option.length === 0) {
      axios.get(process.env.REACT_APP_API_URL, {
        params: {
          // url: `VTS/teacher/${lang}/index?limit=${limit}&page=${page1}&q=${this.state.searchText}`
          url: `api/method/variiance.vts_app.api.v2.teacher.index?lang=${lang}&limit=${limit}&page=${page1}&q=${this.state.searchText}`
        }
      })
        .then((response) => {
          let teachers = response.data.data;
          this.setState({
            teachers,
            results: response.data.data.records,
            total: response.data.meta.total_count,
            current_page: response.data.meta.current_page,
            pag: response.data.meta.pagination,
            isLoading: false,
          });
          this.topscroll();
        });
    } else {
      stringData = selected_option
        .map(function (el, idx) {
          return '"' + el + '"';
        })
        .join(",");
      querySubject = `subject=[${stringData}]`;
      if (selected_option.length === 0) {
      } else {
        query = querySubject;
      }
      axios.get(process.env.REACT_APP_API_URL, {
        params: {
          // url: `VTS/teacher/${lang}/index?limit=${limit}&page=${page1}&${query}&q=${this.state.searchText}`
          url: `api/method/variiance.vts_app.api.v2.teacher.index?lang=${lang}&limit=${limit}&page=${page1}&${query}&q=${this.state.searchText}`

        }
      })
        .then((response) => {
          let teachers = response.data.data;
          this.setState({
            teachers,
            results: response.data.data.records,
            total: response.data.meta.total_count,
            current_page: response.data.meta.current_page,
            pag: response.data.meta.pagination,
            isLoading: false,
          });
          this.topscroll();
        });
    }
  };

  render() {
    const {t} = this.props;
    let {teachers, subjects} = this.state;
    let renderPageNumbers;
    const pageNumbers = [];


    if (this.state.total !== null) {
      for (
        let i = 1;
        i <= Math.ceil(this.state.total / this.state.per_page);
        i++
      ) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers.map((number) => {
        let classes =
          this.state.current_page === number
            ? "page-item page-item-active "
            : "page-item ";

        return (
          <li
            className={classes}
            style={{cursor: "pointer"}}
            key={number}
            onClick={() => this.handleSearch(number)}
          >
            {number}
          </li>
        );
      });
    }

    let singleMetaName = this.state.metaItemsName.map(res => {
      return <meta key={res.key} name={res.key} content={res.value}/>
    })
    let singleMetaProperty = this.state.metaItemsProperty.map(res => {
      return <meta key={res.key} property={res.key} content={res.value}/>
    })

    let subject = subjects.map((sub) => {
      return (
        <div key={sub.name}>
          <Checkbox
            value={sub.name}
            onChange={this.onInput}
            question={sub.name}
            questionid={sub.title}
          >
            <span className="titleinput">{sub.title}</span>
          </Checkbox>
          <br/>
        </div>
      );
    });
    let teacher = teachers.map((res) => {
      let subject = res.subjects.map((resp) => {
        return (
          <Button
            className="buttonexplore1  activecolorspan"
            key={Math.random()}
          >
            <span className="spbtn1 activecolorspan">{resp}</span>
          </Button>
        );
      });
      return (
        <div className="col-lg-3 col-md-6 col-sm-6" key={res.name}>
          <div className="card cardnew">
            <Link to={"/singleteacher/" + res.name}>
              <img
                src={res.image === null || res.image === "" ? img : res.image}
                className=" avatar yes"
                alt={res.image_alt}
              />
            </Link>
            <div className="card-body">
              <Link to={"/singleteacher/" + res.name}

              >
                <h3 className="text-avatar">{res.instructor_name + (res.last_name ? ' ' + res.last_name : '')}</h3>
              </Link>
              <div className="textwrap">
                <p className="Director-of-UserTest">{res.short_description}</p>
              </div>
              <div>{subject}</div>
            </div>
          </div>
        </div>
      );
    });

    let rendered = this.state.isLoading ? (
      <>
        <div className="container cont">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="example">
                <Spin size="large" style={{margin: "auto"}}/>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <>
        {this.state.teachers.length > 0 ? (
          <div className="container cont3">
            <div>
              <h1 className="explore">
                {t("Explore All")}{" "}
                <span className="explorespan">{t("Teachers")}</span>
              </h1>
            </div>
            <div className="row mt-4">
              <div className="col-lg-9">
                <div className="row">{teacher}</div>
              </div>

              <div className="col-lg-3 mobpage allfilter paddingdiv">
                <Button className="copy-bg">
                  <span className="spanfilter">{t("Filter by")}</span>
                </Button>

                <h1 className="Sessions-type">{t("Subject")}</h1>
                <div
                  id="scrollbar-4"
                  style={{overflowY: "scroll", height: "170px"}}
                >
                  {subject}
                </div>
                <br/>
                <br/>
                <form
                  className=" mx-auto searchhome "
                  onSubmit={this.handleSearchSubmit}
                >
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control outlineborder"
                      placeholder={t("Searching For")}
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                      onChange={this.handleSearchInput}
                      value={this.state.searchText || ""}
                    />
                    <button
                      className="btn outlineborder1"
                      type="button"
                      id="button-addon2"
                      onClick={this.handleSearchSubmit}
                    >
                      <img
                        className=" "
                        alt="search icon"
                        src={searchico}
                        width={34}
                        height={34}
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="pb-5">
              <nav aria-label="Page navigation example ">
                <ul className="pagination justify-content-left">
                  {renderPageNumbers}

                  <li
                    className={
                      this.state.current_page === this.state.pag
                        ? "nonee"
                        : "page-item pag"
                    }
                    style={{
                      color: "#908e8e",
                      textDecoration: "none",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.handleSearch(
                        parseInt(this.props.match.params.page) + 1
                      )
                    }
                  >
                    {t("Next")}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        ) : (
          <div className="container cont3">
            <div style={{marginBottom: "50px"}}>
              <h1 className="explore">
                {t("Explore All")}{" "}
                <span className="explorespan">{t("Teachers")}</span>
              </h1>
            </div>
            <div className="row mt-4">
              <div className="col-lg-9">
                <div className="row">
                  <EmptyT/>
                </div>
              </div>

              <div className="col-lg-3 mobpage allfilter paddingdiv">
                <Button className="copy-bg">
                  <span className="spanfilter"> {t("Filter by")}</span>
                </Button>

                <h1 className="Sessions-type">{t("Subject")}</h1>
                <div
                  id="scrollbar-4"
                  style={{overflowY: "scroll", height: "170px"}}
                >
                  {subject}
                </div>
                <br/>
                <br/>
                <form
                  className=" mx-auto searchhome "
                  onSubmit={this.handleSearchSubmit}
                >
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control outlineborder"
                      placeholder={t("Searching For")}
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                      onChange={this.handleSearchInput}
                      value={this.state.searchText || ""}
                    />
                    <button
                      className="btn outlineborder1"
                      type="button"
                      id="button-addon2"
                      onClick={this.handleSearchSubmit}
                    >
                      <img
                        className=" "
                        alt="search icon"
                        src={searchico}
                        width={34}
                        height={34}
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="pb-5">
              <nav aria-label="Page navigation example ">
                <ul className="pagination justify-content-left">
                  {renderPageNumbers}

                  <li
                    className={
                      this.state.current_page >
                      parseInt(this.state.total / this.state.per_page)
                        ? "nonee"
                        : "page-item pag"
                    }
                    style={{
                      color: "#908e8e",
                      textDecoration: "none",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.handleSearch(
                        parseInt(this.props.match.params.page) + 1
                      )
                    }
                  >
                    Next
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )}
      </>
    );
    return <div>
      {rendered}
      <Helmet>
        <link href={"https://vts.variiance.com" + this.state.url} rel="canonical"/>
      </Helmet>
      <MetaTags>
        <title>{this.state.metatittle}</title>
        {singleMetaName}
        {singleMetaProperty}
      </MetaTags>
    </div>
  }
}

export default withRouter(withTranslation()(Teachers));
