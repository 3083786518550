import React from "react";
import {ChatWidget} from "@papercups-io/chat-widget";
import keycloak from "../../keycloak";
import i18next from "i18next";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export const ExamplePage = () => {
    return (
        <>
            <ChatWidget
                accountId={process.env.REACT_APP_CHAT_BOT_ACCOUNT_ID}
                title={i18next.t("Welcome to Variiance")}
                subtitle={i18next.t("Ask us anything in the chat window below 😊")}
                primaryColor="#ff6900"
                greeting={i18next.t("welcome send your questions ?")}
                awayMessage={i18next.t("we are away now we will answer you soon !")}
                newMessagePlaceholder={i18next.t("Start typing...")}
                showAgentAvailability={true}
                agentAvailableText={i18next.t("We're online right now!")}
                agentUnavailableText={i18next.t("We're away at the moment.")}
                requireEmailUpfront={keycloak.authenticated ?false :true}
                iconVariant="outlined"
                position={cookies.get("direction")=== "rtl" ?"left":"right"}
                baseUrl={process.env.REACT_APP_CHAT_BOT_URL}
                customer={keycloak.authenticated ? {
                    name: keycloak.tokenParsed.name,
                    email: keycloak.tokenParsed.email,
                    external_id: keycloak.tokenParsed.sub,
                    phone:keycloak.tokenParsed.phone_number
                }:null}
            />
        </>
    );
};
